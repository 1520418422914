import React, {useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {listeNationalite} from '../../../../constants/ListeNationalite'
import {
    Button,
    ButtonGroup,
    Col,
    FieldsetControl,
    iconRiPhoneFill,
    InputControl,
    Label,
    RadioControl,
    Row,
    SelectControl,
    TextareaControl,
    ToggleV2Control,
} from '@design-system-etat/dsfr-react'
import {validateFormField} from '../../../../constants/ReakHookForm'
import {MESSAGE_ERREUR_CHAMP_INCOMPLET} from '../../../../constants/MessagesErreurs'
import {listePays} from '../../../../constants/ListePays'
import {estAdresseValide, maskTelephone, retourDateDujour} from '../../../../constants/FonctionCommunes'
import type DonneesPersonne from '../../../../constants/DonneesPersonne'

interface Props {
    personneRecherchee?: DonneesPersonne
    creationPersonne: (personne: DonneesPersonne) => void
    modificationPersonne: (personne: DonneesPersonne) => void
    onClose: () => void
}

const InformationsPersonneRecherchee: React.FC<Props> = ({
    personneRecherchee,
    creationPersonne,
    modificationPersonne,
    onClose,
}) => {
    const formulairePersonneRecherchee = useForm<DonneesPersonne>({
        mode: 'onChange',
        defaultValues: {
            civilite: personneRecherchee ? personneRecherchee?.civilite : '',
            nomNaissance: personneRecherchee ? personneRecherchee?.nomNaissance : '',
            nomUsage: personneRecherchee ? personneRecherchee?.nomUsage : '',
            prenom: personneRecherchee ? personneRecherchee?.prenom : '',
            dateNaissance: personneRecherchee ? personneRecherchee?.dateNaissance : '',
            nationalite: personneRecherchee ? personneRecherchee?.nationalite : '',
            nationaliteDeux: personneRecherchee ? personneRecherchee?.nationaliteDeux : '',
            telephone: personneRecherchee ? personneRecherchee?.telephone : '',
            email: personneRecherchee ? personneRecherchee?.email : '',
            confirmationEmail: personneRecherchee ? personneRecherchee?.confirmationEmail : '',
            adresseEtrangere: personneRecherchee ? personneRecherchee?.adresseEtrangere : false,
            numero: personneRecherchee ? personneRecherchee?.numero : undefined,
            complement: personneRecherchee ? personneRecherchee?.complement : '',
            voie: personneRecherchee ? personneRecherchee?.voie : '',
            nomVoie: personneRecherchee ? personneRecherchee?.nomVoie : '',
            residence: personneRecherchee ? personneRecherchee?.residence : '',
            immeuble: personneRecherchee ? personneRecherchee?.immeuble : '',
            etage: personneRecherchee ? personneRecherchee?.etage : '',
            appartement: personneRecherchee ? personneRecherchee?.appartement : '',
            codePostal: personneRecherchee ? personneRecherchee?.codePostal : undefined,
            ville: personneRecherchee ? personneRecherchee?.ville : '',
            cedex: personneRecherchee ? personneRecherchee?.cedex : '',
            paysEtranger: personneRecherchee ? personneRecherchee?.paysEtranger : '',
            rueEtrangere: personneRecherchee ? personneRecherchee?.rueEtrangere : '',
        },
    })

    const nationaliteSelectionnee = formulairePersonneRecherchee.watch('nationalite')
    const adresseEtrangere = formulairePersonneRecherchee.watch('adresseEtrangere')

    const listeNationaliteFormate = useMemo(
        () =>
            listeNationalite.map((nationalite) => ({
                name: nationalite.nationalite,
                value: nationalite.nationalite,
            })),
        [],
    )

    const listeNationaliteSecondaire = useMemo(() => {
        return listeNationaliteFormate.filter((nationalite) => nationalite.value !== nationaliteSelectionnee)
    }, [nationaliteSelectionnee, listeNationaliteFormate])

    const listePaysFormate = useMemo(
        () =>
            listePays.map((pays) => ({
                name: pays.nom_fr_fr,
                value: pays.alpha3,
            })),
        [],
    )

    const ajouterPersonne = (data: DonneesPersonne) => {
        if (onClose) {
            if (modificationPersonne && personneRecherchee) {
                modificationPersonne(data)
            } else if (creationPersonne) {
                creationPersonne(data)
            }
            onClose()
        }
    }

    const valideFonctions = (valeurChamp: string, nomChamp: 'confirmationEmail' | 'email') => {
        if (valeurChamp !== formulairePersonneRecherchee.getValues(nomChamp)) return 'Les email doivent correspondre'
        else if (valeurChamp !== '' && !estAdresseValide(valeurChamp)) {
            return 'Veuillez rentrer un email valide'
        }
    }

    return (
        <form>
            <FieldsetControl
                id="horizontal-radio-control-civilite"
                inline
                legend="Civilité *"
                messages={[
                    {
                        id: 'id-radio-control-civilite',
                        message: formulairePersonneRecherchee.formState.errors.civilite?.message as string,
                    },
                ]}
                state={validateFormField(formulairePersonneRecherchee.formState.errors.civilite)}
            >
                <RadioControl
                    id="horizontal-radio-control-1"
                    label="Monsieur"
                    value="Monsieur"
                    {...formulairePersonneRecherchee.register('civilite', {
                        required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                    })}
                />
                <RadioControl
                    id="horizontal-radio-control-2"
                    label="Madame"
                    value="Madame"
                    {...formulairePersonneRecherchee.register('civilite', {
                        required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                    })}
                />
            </FieldsetControl>

            <Row gutters>
                <Col col={3}>
                    <InputControl
                        id="nom_de_naissance"
                        label="Nom de naissance *"
                        message={formulairePersonneRecherchee.formState.errors.nomNaissance?.message as string}
                        state={validateFormField(formulairePersonneRecherchee.formState.errors.nomNaissance)}
                        {...formulairePersonneRecherchee.register('nomNaissance', {
                            validate: (value) => {
                                const nomUsage = formulairePersonneRecherchee.getValues('nomUsage')
                                if (!value && !nomUsage) {
                                    return MESSAGE_ERREUR_CHAMP_INCOMPLET
                                }
                                return true
                            },
                        })}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="nom_usage"
                        label="Nom d'usage **"
                        message={formulairePersonneRecherchee.formState.errors.nomUsage?.message as string}
                        state={validateFormField(formulairePersonneRecherchee.formState.errors.nomUsage)}
                        {...formulairePersonneRecherchee.register('nomUsage', {
                            validate: (value) => {
                                const nomNaissance = formulairePersonneRecherchee.watch('nomNaissance')
                                if (!value && !nomNaissance) {
                                    return MESSAGE_ERREUR_CHAMP_INCOMPLET
                                }
                                return true
                            },
                        })}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="prenom"
                        label="Prénom *"
                        message={formulairePersonneRecherchee.formState.errors.prenom?.message as string}
                        state={validateFormField(formulairePersonneRecherchee.formState.errors.prenom)}
                        {...formulairePersonneRecherchee.register('prenom', {
                            required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                        })}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="date_de_naissance"
                        label="Date de naissance"
                        type="date"
                        max={retourDateDujour()}
                        {...formulairePersonneRecherchee.register('dateNaissance')}
                    />
                </Col>
            </Row>
            <Row className="fr-mt-2w" gutters>
                <Col col={3}>
                    <SelectControl
                        id="nationalite"
                        options={listeNationaliteFormate}
                        label="Nationalité"
                        placeholder="Sélectionner une nationalité"
                        {...formulairePersonneRecherchee.register('nationalite')}
                    />
                </Col>
                <Col col={3}>
                    <SelectControl
                        id="nationalite_secondaire"
                        options={listeNationaliteSecondaire}
                        label="Nationalité secondaire"
                        placeholder="Sélectionner une nationalité"
                        {...formulairePersonneRecherchee.register('nationaliteDeux')}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="numero_telephone"
                        label="Téléphone"
                        icon={iconRiPhoneFill}
                        maskFonction={maskTelephone}
                        {...formulairePersonneRecherchee.register('telephone')}
                    />
                </Col>
            </Row>

            <Row className="fr-mt-2w fr-mb-2w" gutters>
                <Col col={3}>
                    <InputControl
                        id="email"
                        label="Email"
                        message={formulairePersonneRecherchee.formState.errors.email?.message as string}
                        state={validateFormField(formulairePersonneRecherchee.formState.errors.email)}
                        {...formulairePersonneRecherchee.register('email', {
                            validate: (value) => valideFonctions(value, 'confirmationEmail'),
                            deps: ['confirmationEmail'],
                        })}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="confirmation_email"
                        label="Confirmation email"
                        message={formulairePersonneRecherchee.formState.errors.email?.message as string}
                        state={validateFormField(formulairePersonneRecherchee.formState.errors.email)}
                        {...formulairePersonneRecherchee.register('confirmationEmail', {
                            validate: (value) => valideFonctions(value, 'email'),
                            deps: ['email'],
                        })}
                    />
                </Col>
            </Row>
            <Label>Adresse étrangère ? *</Label>
            <Col col={1}>
                <ToggleV2Control
                    label={''}
                    id={'adresseEtrangere'}
                    {...formulairePersonneRecherchee.register('adresseEtrangere')}
                />
            </Col>
            {!adresseEtrangere ? (
                <>
                    <Row gutters>
                        <Col col={2}>
                            <InputControl
                                id="numero_adresse"
                                label="Numéro"
                                {...formulairePersonneRecherchee.register('numero')}
                            />
                        </Col>
                        <Col col={2}>
                            <InputControl
                                id="complement_adresse"
                                label="Complément"
                                {...formulairePersonneRecherchee.register('complement')}
                            />
                        </Col>
                        <Col col={2}>
                            <InputControl
                                id="voie_adresse"
                                label="Voie"
                                {...formulairePersonneRecherchee.register('voie')}
                            />
                        </Col>
                        <Col col={6}>
                            <InputControl
                                id="nom_voie_adresse"
                                label="Nom de la voie"
                                {...formulairePersonneRecherchee.register('nomVoie')}
                            />
                        </Col>
                    </Row>
                    <Row gutters>
                        <Col col={4}>
                            <InputControl
                                id={'residence'}
                                label={'Résidence'}
                                {...formulairePersonneRecherchee.register('residence')}
                            />
                        </Col>
                        <Col col={4}>
                            <InputControl
                                id={'immeuble'}
                                label={'Immeuble'}
                                {...formulairePersonneRecherchee.register('immeuble')}
                            />
                        </Col>
                        <Col col={2}>
                            <InputControl
                                id={'etage'}
                                label={'Etage'}
                                {...formulairePersonneRecherchee.register('etage')}
                            />
                        </Col>
                        <Col col={2}>
                            <InputControl
                                id={'appartement'}
                                label={'Appartement'}
                                {...formulairePersonneRecherchee.register('appartement')}
                            />
                        </Col>
                    </Row>
                    <Row gutters className={'fr-mb-4w'}>
                        <Col col={2}>
                            <InputControl
                                id={'codePostal'}
                                label={'Code Postal'}
                                {...formulairePersonneRecherchee.register('codePostal')}
                            />
                        </Col>
                        <Col col={6}>
                            <InputControl
                                id={'ville'}
                                label={'Ville'}
                                {...formulairePersonneRecherchee.register('ville')}
                            />
                        </Col>
                        <Col col={2}>
                            <InputControl
                                id={'cedex'}
                                label={'Cedex'}
                                {...formulairePersonneRecherchee.register('cedex')}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row gutters>
                        <Col col={6}>
                            <TextareaControl
                                id={'adresse'}
                                label={'Adresse'}
                                {...formulairePersonneRecherchee.register('rueEtrangere')}
                            />
                        </Col>
                    </Row>

                    <Row gutters className={'fr-mb-4w'}>
                        <Col col={2}>
                            <InputControl
                                id={'codePostal'}
                                label={'Code Postal'}
                                {...formulairePersonneRecherchee.register('codePostal')}
                            />
                        </Col>
                        <Col col={4}>
                            <InputControl
                                id={'ville'}
                                label={'Ville'}
                                {...formulairePersonneRecherchee.register('ville')}
                            />
                        </Col>
                        <Col col={3}>
                            <SelectControl
                                id="pays"
                                options={listePaysFormate}
                                label="Pays"
                                placeholder="Sélectionner un pays"
                                {...formulairePersonneRecherchee.register('paysEtranger')}
                            />
                        </Col>
                    </Row>
                </>
            )}
            <Col>
                <Row className={'legende-formulaire'}>{'* Champ obligatoire'}</Row>
                <Row className={'legende-formulaire'}>{'** Champ obligatoire si nom de naissance manquant'}</Row>
            </Col>
            <ButtonGroup inline align={'right'} className={'fixed-button-group'}>
                <Button type={'button'} variant={'secondary'} onClick={onClose}>
                    Annuler
                </Button>
                <Button type="button" onClick={formulairePersonneRecherchee.handleSubmit(ajouterPersonne)}>
                    Valider
                </Button>
            </ButtonGroup>
        </form>
    )
}

export default InformationsPersonneRecherchee
