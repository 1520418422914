import React, {useMemo} from 'react'
import {Col, InputControl, Row, SelectControl, TextareaControl} from '@design-system-etat/dsfr-react'
import {useFormContext} from 'react-hook-form'
import {listePays} from '../../../../constants/ListePays'

interface PropsAdresse {
    estFrancais: boolean
}

const Adresse: React.FC<PropsAdresse> = ({estFrancais}) => {
    const {register} = useFormContext()

    const listeNationaliteFormate = useMemo(
        () =>
            listePays.map((pays) => ({
                name: pays.nom_fr_fr,
                value: pays.alpha3,
            })),
        [],
    )

    return (
        <>
            {estFrancais ? (
                <>
                    <Row gutters>
                        <Col col={2}>
                            <InputControl id="numero_adresse" label="Numéro" {...register('numero')} />
                        </Col>
                        <Col col={2}>
                            <InputControl id="complement_adresse" label="Complément" {...register('complement')} />
                        </Col>
                        <Col col={2}>
                            <InputControl id="voie_adresse" label="Voie" {...register('voie')} />
                        </Col>
                        <Col col={6}>
                            <InputControl id="nom_voie_adresse" label="Nom de la voie" {...register('nomVoie')} />
                        </Col>
                    </Row>
                    <Row gutters>
                        <Col col={4}>
                            <InputControl id={'residence'} label={'Résidence'} {...register('residence')} />
                        </Col>
                        <Col col={4}>
                            <InputControl id={'immeuble'} label={'Immeuble'} {...register('immeuble')} />
                        </Col>
                        <Col col={2}>
                            <InputControl id={'etage'} label={'Etage'} {...register('etage')} />
                        </Col>
                        <Col col={2}>
                            <InputControl id={'appartement'} label={'Appartement'} {...register('appartement')} />
                        </Col>
                    </Row>
                    <Row gutters className={'fr-mb-4w'}>
                        <Col col={2}>
                            <InputControl id={'codePostal'} label={'Code Postal'} {...register('codePostal')} />
                        </Col>
                        <Col col={6}>
                            <InputControl id={'ville'} label={'Ville'} {...register('ville')} />
                        </Col>
                        <Col col={2}>
                            <InputControl id={'cedex'} label={'Cedex'} {...register('cedex')} />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row gutters>
                        <Col col={6}>
                            <TextareaControl id={'rueEtrangere'} label={'Adresse'} {...register('rueEtrangere')} />
                        </Col>
                    </Row>

                    <Row gutters className={'fr-mb-4w'}>
                        <Col col={2}>
                            <InputControl id={'codePostal'} label={'Code Postal'} {...register('codePostal')} />
                        </Col>
                        <Col col={4}>
                            <InputControl id={'ville'} label={'Ville'} {...register('ville')} />
                        </Col>
                        <Col col={3}>
                            <SelectControl
                                id="pays"
                                options={listeNationaliteFormate}
                                label="Pays"
                                placeholder="Sélectionner un pays"
                                {...register('paysEtranger')}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default Adresse
