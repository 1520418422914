import React from 'react'
import {LoadingContextProvider} from './contexts/LoadingContext'
import Loader from './components/Loader'
import Header from './pages/header/Header'
import {Route, Routes} from 'react-router'
import Footer from './pages/footer/Footer'
import {URLPageEnum} from './constants/URLPageEnum'
import Accueil from './pages/accueil/Accueil'
import RecherchePersonne from './pages/recherche-personne/RecherchePersonne'
import {BrowserRouter} from 'react-router-dom'
import {EvenementContextProvider} from './contexts/EvenementContext'

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <LoadingContextProvider>
                <EvenementContextProvider>
                    <Loader />
                    <Header />
                    <main role="main" id="main">
                        <Routes>
                            <Route path={URLPageEnum.ACCUEIL} element={<Accueil />} />
                            <Route path={URLPageEnum.RECHERCHE_PERSONNE} element={<RecherchePersonne />} />)
                        </Routes>
                    </main>
                    <Footer />
                </EvenementContextProvider>
            </LoadingContextProvider>
        </BrowserRouter>
    )
}

export default App
