export const listePays = [
    {
        code: 4,
        alpha2: 'AF',
        alpha3: 'AFG',
        nom_fr_fr: 'Afghanistan',
        nom_en_gb: 'Afghanistan',
    },
    {
        code: 8,
        alpha2: 'AL',
        alpha3: 'ALB',
        nom_fr_fr: 'Albanie',
        nom_en_gb: 'Albania',
    },
    {
        code: 10,
        alpha2: 'AQ',
        alpha3: 'ATA',
        nom_fr_fr: 'Antarctique',
        nom_en_gb: 'Antarctica',
    },
    {
        code: 12,
        alpha2: 'DZ',
        alpha3: 'DZA',
        nom_fr_fr: 'Algérie',
        nom_en_gb: 'Algeria',
    },
    {
        code: 16,
        alpha2: 'AS',
        alpha3: 'ASM',
        nom_fr_fr: 'Samoa Américaines',
        nom_en_gb: 'American Samoa',
    },
    {
        code: 20,
        alpha2: 'AD',
        alpha3: 'AND',
        nom_fr_fr: 'Andorre',
        nom_en_gb: 'Andorra',
    },
    {
        code: 24,
        alpha2: 'AO',
        alpha3: 'AGO',
        nom_fr_fr: 'Angola',
        nom_en_gb: 'Angola',
    },
    {
        code: 28,
        alpha2: 'AG',
        alpha3: 'ATG',
        nom_fr_fr: 'Antigua-et-Barbuda',
        nom_en_gb: 'Antigua and Barbuda',
    },
    {
        code: 31,
        alpha2: 'AZ',
        alpha3: 'AZE',
        nom_fr_fr: 'Azerbaïdjan',
        nom_en_gb: 'Azerbaijan',
    },
    {
        code: 32,
        alpha2: 'AR',
        alpha3: 'ARG',
        nom_fr_fr: 'Argentine',
        nom_en_gb: 'Argentina',
    },
    {
        code: 36,
        alpha2: 'AU',
        alpha3: 'AUS',
        nom_fr_fr: 'Australie',
        nom_en_gb: 'Australia',
    },
    {
        code: 40,
        alpha2: 'AT',
        alpha3: 'AUT',
        nom_fr_fr: 'Autriche',
        nom_en_gb: 'Austria',
    },
    {
        code: 44,
        alpha2: 'BS',
        alpha3: 'BHS',
        nom_fr_fr: 'Bahamas',
        nom_en_gb: 'Bahamas',
    },
    {
        code: 48,
        alpha2: 'BH',
        alpha3: 'BHR',
        nom_fr_fr: 'Bahreïn',
        nom_en_gb: 'Bahrain',
    },
    {
        code: 50,
        alpha2: 'BD',
        alpha3: 'BGD',
        nom_fr_fr: 'Bangladesh',
        nom_en_gb: 'Bangladesh',
    },
    {
        code: 51,
        alpha2: 'AM',
        alpha3: 'ARM',
        nom_fr_fr: 'Arménie',
        nom_en_gb: 'Armenia',
    },
    {
        code: 52,
        alpha2: 'BB',
        alpha3: 'BRB',
        nom_fr_fr: 'Barbade',
        nom_en_gb: 'Barbados',
    },
    {
        code: 56,
        alpha2: 'BE',
        alpha3: 'BEL',
        nom_fr_fr: 'Belgique',
        nom_en_gb: 'Belgium',
    },
    {
        code: 60,
        alpha2: 'BM',
        alpha3: 'BMU',
        nom_fr_fr: 'Bermudes',
        nom_en_gb: 'Bermuda',
    },
    {
        code: 64,
        alpha2: 'BT',
        alpha3: 'BTN',
        nom_fr_fr: 'Bhoutan',
        nom_en_gb: 'Bhutan',
    },
    {
        code: 68,
        alpha2: 'BO',
        alpha3: 'BOL',
        nom_fr_fr: 'Bolivie',
        nom_en_gb: 'Bolivia',
    },
    {
        code: 70,
        alpha2: 'BA',
        alpha3: 'BIH',
        nom_fr_fr: 'Bosnie-Herzégovine',
        nom_en_gb: 'Bosnia and Herzegovina',
    },
    {
        code: 72,
        alpha2: 'BW',
        alpha3: 'BWA',
        nom_fr_fr: 'Botswana',
        nom_en_gb: 'Botswana',
    },
    {
        code: 74,
        alpha2: 'BV',
        alpha3: 'BVT',
        nom_fr_fr: 'Île Bouvet',
        nom_en_gb: 'Bouvet Island',
    },
    {
        code: 76,
        alpha2: 'BR',
        alpha3: 'BRA',
        nom_fr_fr: 'Brésil',
        nom_en_gb: 'Brazil',
    },
    {
        code: 84,
        alpha2: 'BZ',
        alpha3: 'BLZ',
        nom_fr_fr: 'Belize',
        nom_en_gb: 'Belize',
    },
    {
        code: 86,
        alpha2: 'IO',
        alpha3: 'IOT',
        nom_fr_fr: "Territoire Britannique de l'Océan Indien",
        nom_en_gb: 'British Indian Ocean Territory',
    },
    {
        code: 90,
        alpha2: 'SB',
        alpha3: 'SLB',
        nom_fr_fr: 'Îles Salomon',
        nom_en_gb: 'Solomon Islands',
    },
    {
        code: 92,
        alpha2: 'VG',
        alpha3: 'VGB',
        nom_fr_fr: 'Îles Vierges Britanniques',
        nom_en_gb: 'British Virgin Islands',
    },
    {
        code: 96,
        alpha2: 'BN',
        alpha3: 'BRN',
        nom_fr_fr: 'Brunéi Darussalam',
        nom_en_gb: 'Brunei Darussalam',
    },
    {
        code: 100,
        alpha2: 'BG',
        alpha3: 'BGR',
        nom_fr_fr: 'Bulgarie',
        nom_en_gb: 'Bulgaria',
    },
    {
        code: 104,
        alpha2: 'MM',
        alpha3: 'MMR',
        nom_fr_fr: 'Myanmar',
        nom_en_gb: 'Myanmar',
    },
    {
        code: 108,
        alpha2: 'BI',
        alpha3: 'BDI',
        nom_fr_fr: 'Burundi',
        nom_en_gb: 'Burundi',
    },
    {
        code: 112,
        alpha2: 'BY',
        alpha3: 'BLR',
        nom_fr_fr: 'Bélarus',
        nom_en_gb: 'Belarus',
    },
    {
        code: 116,
        alpha2: 'KH',
        alpha3: 'KHM',
        nom_fr_fr: 'Cambodge',
        nom_en_gb: 'Cambodia',
    },
    {
        code: 120,
        alpha2: 'CM',
        alpha3: 'CMR',
        nom_fr_fr: 'Cameroun',
        nom_en_gb: 'Cameroon',
    },
    {
        code: 124,
        alpha2: 'CA',
        alpha3: 'CAN',
        nom_fr_fr: 'Canada',
        nom_en_gb: 'Canada',
    },
    {
        code: 132,
        alpha2: 'CV',
        alpha3: 'CPV',
        nom_fr_fr: 'Cap-vert',
        nom_en_gb: 'Cape Verde',
    },
    {
        code: 136,
        alpha2: 'KY',
        alpha3: 'CYM',
        nom_fr_fr: 'Îles Caïmanes',
        nom_en_gb: 'Cayman Islands',
    },
    {
        code: 140,
        alpha2: 'CF',
        alpha3: 'CAF',
        nom_fr_fr: 'République Centrafricaine',
        nom_en_gb: 'Central African',
    },
    {
        code: 144,
        alpha2: 'LK',
        alpha3: 'LKA',
        nom_fr_fr: 'Sri Lanka',
        nom_en_gb: 'Sri Lanka',
    },
    {
        code: 148,
        alpha2: 'TD',
        alpha3: 'TCD',
        nom_fr_fr: 'Tchad',
        nom_en_gb: 'Chad',
    },
    {
        code: 152,
        alpha2: 'CL',
        alpha3: 'CHL',
        nom_fr_fr: 'Chili',
        nom_en_gb: 'Chile',
    },
    {
        code: 156,
        alpha2: 'CN',
        alpha3: 'CHN',
        nom_fr_fr: 'Chine',
        nom_en_gb: 'China',
    },
    {
        code: 158,
        alpha2: 'TW',
        alpha3: 'TWN',
        nom_fr_fr: 'Taïwan',
        nom_en_gb: 'Taiwan',
    },
    {
        code: 162,
        alpha2: 'CX',
        alpha3: 'CXR',
        nom_fr_fr: 'Île Christmas',
        nom_en_gb: 'Christmas Island',
    },
    {
        code: 166,
        alpha2: 'CC',
        alpha3: 'CCK',
        nom_fr_fr: 'Îles Cocos (Keeling)',
        nom_en_gb: 'Cocos (Keeling) Islands',
    },
    {
        code: 170,
        alpha2: 'CO',
        alpha3: 'COL',
        nom_fr_fr: 'Colombie',
        nom_en_gb: 'Colombia',
    },
    {
        code: 174,
        alpha2: 'KM',
        alpha3: 'COM',
        nom_fr_fr: 'Comores',
        nom_en_gb: 'Comoros',
    },
    {
        code: 175,
        alpha2: 'YT',
        alpha3: 'MYT',
        nom_fr_fr: 'Mayotte',
        nom_en_gb: 'Mayotte',
    },
    {
        code: 178,
        alpha2: 'CG',
        alpha3: 'COG',
        nom_fr_fr: 'République du Congo',
        nom_en_gb: 'Republic of the Congo',
    },
    {
        code: 180,
        alpha2: 'CD',
        alpha3: 'COD',
        nom_fr_fr: 'République Démocratique du Congo',
        nom_en_gb: 'The Democratic Republic Of The Congo',
    },
    {
        code: 184,
        alpha2: 'CK',
        alpha3: 'COK',
        nom_fr_fr: 'Îles Cook',
        nom_en_gb: 'Cook Islands',
    },
    {
        code: 188,
        alpha2: 'CR',
        alpha3: 'CRI',
        nom_fr_fr: 'Costa Rica',
        nom_en_gb: 'Costa Rica',
    },
    {
        code: 191,
        alpha2: 'HR',
        alpha3: 'HRV',
        nom_fr_fr: 'Croatie',
        nom_en_gb: 'Croatia',
    },
    {
        code: 192,
        alpha2: 'CU',
        alpha3: 'CUB',
        nom_fr_fr: 'Cuba',
        nom_en_gb: 'Cuba',
    },
    {
        code: 196,
        alpha2: 'CY',
        alpha3: 'CYP',
        nom_fr_fr: 'Chypre',
        nom_en_gb: 'Cyprus',
    },
    {
        code: 203,
        alpha2: 'CZ',
        alpha3: 'CZE',
        nom_fr_fr: 'République Tchèque',
        nom_en_gb: 'Czech Republic',
    },
    {
        code: 204,
        alpha2: 'BJ',
        alpha3: 'BEN',
        nom_fr_fr: 'Bénin',
        nom_en_gb: 'Benin',
    },
    {
        code: 208,
        alpha2: 'DK',
        alpha3: 'DNK',
        nom_fr_fr: 'Danemark',
        nom_en_gb: 'Denmark',
    },
    {
        code: 212,
        alpha2: 'DM',
        alpha3: 'DMA',
        nom_fr_fr: 'Dominique',
        nom_en_gb: 'Dominica',
    },
    {
        code: 214,
        alpha2: 'DO',
        alpha3: 'DOM',
        nom_fr_fr: 'République Dominicaine',
        nom_en_gb: 'Dominican Republic',
    },
    {
        code: 218,
        alpha2: 'EC',
        alpha3: 'ECU',
        nom_fr_fr: 'Équateur',
        nom_en_gb: 'Ecuador',
    },
    {
        code: 222,
        alpha2: 'SV',
        alpha3: 'SLV',
        nom_fr_fr: 'El Salvador',
        nom_en_gb: 'El Salvador',
    },
    {
        code: 226,
        alpha2: 'GQ',
        alpha3: 'GNQ',
        nom_fr_fr: 'Guinée Équatoriale',
        nom_en_gb: 'Equatorial Guinea',
    },
    {
        code: 231,
        alpha2: 'ET',
        alpha3: 'ETH',
        nom_fr_fr: 'Éthiopie',
        nom_en_gb: 'Ethiopia',
    },
    {
        code: 232,
        alpha2: 'ER',
        alpha3: 'ERI',
        nom_fr_fr: 'Érythrée',
        nom_en_gb: 'Eritrea',
    },
    {
        code: 233,
        alpha2: 'EE',
        alpha3: 'EST',
        nom_fr_fr: 'Estonie',
        nom_en_gb: 'Estonia',
    },
    {
        code: 234,
        alpha2: 'FO',
        alpha3: 'FRO',
        nom_fr_fr: 'Îles Féroé',
        nom_en_gb: 'Faroe Islands',
    },
    {
        code: 238,
        alpha2: 'FK',
        alpha3: 'FLK',
        nom_fr_fr: 'Îles (malvinas) Falkland',
        nom_en_gb: 'Falkland Islands',
    },
    {
        code: 239,
        alpha2: 'GS',
        alpha3: 'SGS',
        nom_fr_fr: 'Géorgie du Sud et les Îles Sandwich du Sud',
        nom_en_gb: 'South Georgia and the South Sandwich Islands',
    },
    {
        code: 242,
        alpha2: 'FJ',
        alpha3: 'FJI',
        nom_fr_fr: 'Fidji',
        nom_en_gb: 'Fiji',
    },
    {
        code: 246,
        alpha2: 'FI',
        alpha3: 'FIN',
        nom_fr_fr: 'Finlande',
        nom_en_gb: 'Finland',
    },
    {
        code: 248,
        alpha2: 'AX',
        alpha3: 'ALA',
        nom_fr_fr: 'Îles Åland',
        nom_en_gb: 'Åland Islands',
    },
    {
        code: 250,
        alpha2: 'FR',
        alpha3: 'FRA',
        nom_fr_fr: 'France',
        nom_en_gb: 'France',
    },
    {
        code: 254,
        alpha2: 'GF',
        alpha3: 'GUF',
        nom_fr_fr: 'Guyane Française',
        nom_en_gb: 'French Guiana',
    },
    {
        code: 258,
        alpha2: 'PF',
        alpha3: 'PYF',
        nom_fr_fr: 'Polynésie Française',
        nom_en_gb: 'French Polynesia',
    },
    {
        code: 260,
        alpha2: 'TF',
        alpha3: 'ATF',
        nom_fr_fr: 'Terres Australes Françaises',
        nom_en_gb: 'French Southern Territories',
    },
    {
        code: 262,
        alpha2: 'DJ',
        alpha3: 'DJI',
        nom_fr_fr: 'Djibouti',
        nom_en_gb: 'Djibouti',
    },
    {
        code: 266,
        alpha2: 'GA',
        alpha3: 'GAB',
        nom_fr_fr: 'Gabon',
        nom_en_gb: 'Gabon',
    },
    {
        code: 268,
        alpha2: 'GE',
        alpha3: 'GEO',
        nom_fr_fr: 'Géorgie',
        nom_en_gb: 'Georgia',
    },
    {
        code: 270,
        alpha2: 'GM',
        alpha3: 'GMB',
        nom_fr_fr: 'Gambie',
        nom_en_gb: 'Gambia',
    },
    {
        code: 275,
        alpha2: 'PS',
        alpha3: 'PSE',
        nom_fr_fr: 'Territoire Palestinien Occupé',
        nom_en_gb: 'Occupied Palestinian Territory',
    },
    {
        code: 276,
        alpha2: 'DE',
        alpha3: 'DEU',
        nom_fr_fr: 'Allemagne',
        nom_en_gb: 'Germany',
    },
    {
        code: 288,
        alpha2: 'GH',
        alpha3: 'GHA',
        nom_fr_fr: 'Ghana',
        nom_en_gb: 'Ghana',
    },
    {
        code: 292,
        alpha2: 'GI',
        alpha3: 'GIB',
        nom_fr_fr: 'Gibraltar',
        nom_en_gb: 'Gibraltar',
    },
    {
        code: 296,
        alpha2: 'KI',
        alpha3: 'KIR',
        nom_fr_fr: 'Kiribati',
        nom_en_gb: 'Kiribati',
    },
    {
        code: 300,
        alpha2: 'GR',
        alpha3: 'GRC',
        nom_fr_fr: 'Grèce',
        nom_en_gb: 'Greece',
    },
    {
        code: 304,
        alpha2: 'GL',
        alpha3: 'GRL',
        nom_fr_fr: 'Groenland',
        nom_en_gb: 'Greenland',
    },
    {
        code: 308,
        alpha2: 'GD',
        alpha3: 'GRD',
        nom_fr_fr: 'Grenade',
        nom_en_gb: 'Grenada',
    },
    {
        code: 312,
        alpha2: 'GP',
        alpha3: 'GLP',
        nom_fr_fr: 'Guadeloupe',
        nom_en_gb: 'Guadeloupe',
    },
    {
        code: 316,
        alpha2: 'GU',
        alpha3: 'GUM',
        nom_fr_fr: 'Guam',
        nom_en_gb: 'Guam',
    },
    {
        code: 320,
        alpha2: 'GT',
        alpha3: 'GTM',
        nom_fr_fr: 'Guatemala',
        nom_en_gb: 'Guatemala',
    },
    {
        code: 324,
        alpha2: 'GN',
        alpha3: 'GIN',
        nom_fr_fr: 'Guinée',
        nom_en_gb: 'Guinea',
    },
    {
        code: 328,
        alpha2: 'GY',
        alpha3: 'GUY',
        nom_fr_fr: 'Guyana',
        nom_en_gb: 'Guyana',
    },
    {
        code: 332,
        alpha2: 'HT',
        alpha3: 'HTI',
        nom_fr_fr: 'Haïti',
        nom_en_gb: 'Haiti',
    },
    {
        code: 334,
        alpha2: 'HM',
        alpha3: 'HMD',
        nom_fr_fr: 'Îles Heard et Mcdonald',
        nom_en_gb: 'Heard Island and McDonald Islands',
    },
    {
        code: 336,
        alpha2: 'VA',
        alpha3: 'VAT',
        nom_fr_fr: 'Saint-Siège (état de la Cité du Vatican)',
        nom_en_gb: 'Vatican City State',
    },
    {
        code: 340,
        alpha2: 'HN',
        alpha3: 'HND',
        nom_fr_fr: 'Honduras',
        nom_en_gb: 'Honduras',
    },
    {
        code: 344,
        alpha2: 'HK',
        alpha3: 'HKG',
        nom_fr_fr: 'Hong-Kong',
        nom_en_gb: 'Hong Kong',
    },
    {
        code: 348,
        alpha2: 'HU',
        alpha3: 'HUN',
        nom_fr_fr: 'Hongrie',
        nom_en_gb: 'Hungary',
    },
    {
        code: 352,
        alpha2: 'IS',
        alpha3: 'ISL',
        nom_fr_fr: 'Islande',
        nom_en_gb: 'Iceland',
    },
    {
        code: 356,
        alpha2: 'IN',
        alpha3: 'IND',
        nom_fr_fr: 'Inde',
        nom_en_gb: 'India',
    },
    {
        code: 360,
        alpha2: 'ID',
        alpha3: 'IDN',
        nom_fr_fr: 'Indonésie',
        nom_en_gb: 'Indonesia',
    },
    {
        code: 364,
        alpha2: 'IR',
        alpha3: 'IRN',
        nom_fr_fr: "République Islamique d'Iran",
        nom_en_gb: 'Islamic Republic of Iran',
    },
    {
        code: 368,
        alpha2: 'IQ',
        alpha3: 'IRQ',
        nom_fr_fr: 'Iraq',
        nom_en_gb: 'Iraq',
    },
    {
        code: 372,
        alpha2: 'IE',
        alpha3: 'IRL',
        nom_fr_fr: 'Irlande',
        nom_en_gb: 'Ireland',
    },
    {
        code: 376,
        alpha2: 'IL',
        alpha3: 'ISR',
        nom_fr_fr: 'Israël',
        nom_en_gb: 'Israel',
    },
    {
        code: 380,
        alpha2: 'IT',
        alpha3: 'ITA',
        nom_fr_fr: 'Italie',
        nom_en_gb: 'Italy',
    },
    {
        code: 384,
        alpha2: 'CI',
        alpha3: 'CIV',
        nom_fr_fr: "Côte d'Ivoire",
        nom_en_gb: "Côte d'Ivoire",
    },
    {
        code: 388,
        alpha2: 'JM',
        alpha3: 'JAM',
        nom_fr_fr: 'Jamaïque',
        nom_en_gb: 'Jamaica',
    },
    {
        code: 392,
        alpha2: 'JP',
        alpha3: 'JPN',
        nom_fr_fr: 'Japon',
        nom_en_gb: 'Japan',
    },
    {
        code: 398,
        alpha2: 'KZ',
        alpha3: 'KAZ',
        nom_fr_fr: 'Kazakhstan',
        nom_en_gb: 'Kazakhstan',
    },
    {
        code: 400,
        alpha2: 'JO',
        alpha3: 'JOR',
        nom_fr_fr: 'Jordanie',
        nom_en_gb: 'Jordan',
    },
    {
        code: 404,
        alpha2: 'KE',
        alpha3: 'KEN',
        nom_fr_fr: 'Kenya',
        nom_en_gb: 'Kenya',
    },
    {
        code: 408,
        alpha2: 'KP',
        alpha3: 'PRK',
        nom_fr_fr: 'République Populaire Démocratique de Corée',
        nom_en_gb: "Democratic People's Republic of Korea",
    },
    {
        code: 410,
        alpha2: 'KR',
        alpha3: 'KOR',
        nom_fr_fr: 'République de Corée',
        nom_en_gb: 'Republic of Korea',
    },
    {
        code: 414,
        alpha2: 'KW',
        alpha3: 'KWT',
        nom_fr_fr: 'Koweït',
        nom_en_gb: 'Kuwait',
    },
    {
        code: 417,
        alpha2: 'KG',
        alpha3: 'KGZ',
        nom_fr_fr: 'Kirghizistan',
        nom_en_gb: 'Kyrgyzstan',
    },
    {
        code: 418,
        alpha2: 'LA',
        alpha3: 'LAO',
        nom_fr_fr: 'République Démocratique Populaire Lao',
        nom_en_gb: "Lao People's Democratic Republic",
    },
    {
        code: 422,
        alpha2: 'LB',
        alpha3: 'LBN',
        nom_fr_fr: 'Liban',
        nom_en_gb: 'Lebanon',
    },
    {
        code: 426,
        alpha2: 'LS',
        alpha3: 'LSO',
        nom_fr_fr: 'Lesotho',
        nom_en_gb: 'Lesotho',
    },
    {
        code: 428,
        alpha2: 'LV',
        alpha3: 'LVA',
        nom_fr_fr: 'Lettonie',
        nom_en_gb: 'Latvia',
    },
    {
        code: 430,
        alpha2: 'LR',
        alpha3: 'LBR',
        nom_fr_fr: 'Libéria',
        nom_en_gb: 'Liberia',
    },
    {
        code: 434,
        alpha2: 'LY',
        alpha3: 'LBY',
        nom_fr_fr: 'Jamahiriya Arabe Libyenne',
        nom_en_gb: 'Libyan Arab Jamahiriya',
    },
    {
        code: 438,
        alpha2: 'LI',
        alpha3: 'LIE',
        nom_fr_fr: 'Liechtenstein',
        nom_en_gb: 'Liechtenstein',
    },
    {
        code: 440,
        alpha2: 'LT',
        alpha3: 'LTU',
        nom_fr_fr: 'Lituanie',
        nom_en_gb: 'Lithuania',
    },
    {
        code: 442,
        alpha2: 'LU',
        alpha3: 'LUX',
        nom_fr_fr: 'Luxembourg',
        nom_en_gb: 'Luxembourg',
    },
    {
        code: 446,
        alpha2: 'MO',
        alpha3: 'MAC',
        nom_fr_fr: 'Macao',
        nom_en_gb: 'Macao',
    },
    {
        code: 450,
        alpha2: 'MG',
        alpha3: 'MDG',
        nom_fr_fr: 'Madagascar',
        nom_en_gb: 'Madagascar',
    },
    {
        code: 454,
        alpha2: 'MW',
        alpha3: 'MWI',
        nom_fr_fr: 'Malawi',
        nom_en_gb: 'Malawi',
    },
    {
        code: 458,
        alpha2: 'MY',
        alpha3: 'MYS',
        nom_fr_fr: 'Malaisie',
        nom_en_gb: 'Malaysia',
    },
    {
        code: 462,
        alpha2: 'MV',
        alpha3: 'MDV',
        nom_fr_fr: 'Maldives',
        nom_en_gb: 'Maldives',
    },
    {
        code: 466,
        alpha2: 'ML',
        alpha3: 'MLI',
        nom_fr_fr: 'Mali',
        nom_en_gb: 'Mali',
    },
    {
        code: 470,
        alpha2: 'MT',
        alpha3: 'MLT',
        nom_fr_fr: 'Malte',
        nom_en_gb: 'Malta',
    },
    {
        code: 474,
        alpha2: 'MQ',
        alpha3: 'MTQ',
        nom_fr_fr: 'Martinique',
        nom_en_gb: 'Martinique',
    },
    {
        code: 478,
        alpha2: 'MR',
        alpha3: 'MRT',
        nom_fr_fr: 'Mauritanie',
        nom_en_gb: 'Mauritania',
    },
    {
        code: 480,
        alpha2: 'MU',
        alpha3: 'MUS',
        nom_fr_fr: 'Maurice',
        nom_en_gb: 'Mauritius',
    },
    {
        code: 484,
        alpha2: 'MX',
        alpha3: 'MEX',
        nom_fr_fr: 'Mexique',
        nom_en_gb: 'Mexico',
    },
    {
        code: 492,
        alpha2: 'MC',
        alpha3: 'MCO',
        nom_fr_fr: 'Monaco',
        nom_en_gb: 'Monaco',
    },
    {
        code: 496,
        alpha2: 'MN',
        alpha3: 'MNG',
        nom_fr_fr: 'Mongolie',
        nom_en_gb: 'Mongolia',
    },
    {
        code: 498,
        alpha2: 'MD',
        alpha3: 'MDA',
        nom_fr_fr: 'République de Moldova',
        nom_en_gb: 'Republic of Moldova',
    },
    {
        code: 500,
        alpha2: 'MS',
        alpha3: 'MSR',
        nom_fr_fr: 'Montserrat',
        nom_en_gb: 'Montserrat',
    },
    {
        code: 504,
        alpha2: 'MA',
        alpha3: 'MAR',
        nom_fr_fr: 'Maroc',
        nom_en_gb: 'Morocco',
    },
    {
        code: 508,
        alpha2: 'MZ',
        alpha3: 'MOZ',
        nom_fr_fr: 'Mozambique',
        nom_en_gb: 'Mozambique',
    },
    {
        code: 512,
        alpha2: 'OM',
        alpha3: 'OMN',
        nom_fr_fr: 'Oman',
        nom_en_gb: 'Oman',
    },
    {
        code: 516,
        alpha2: 'NA',
        alpha3: 'NAM',
        nom_fr_fr: 'Namibie',
        nom_en_gb: 'Namibia',
    },
    {
        code: 520,
        alpha2: 'NR',
        alpha3: 'NRU',
        nom_fr_fr: 'Nauru',
        nom_en_gb: 'Nauru',
    },
    {
        code: 524,
        alpha2: 'NP',
        alpha3: 'NPL',
        nom_fr_fr: 'Népal',
        nom_en_gb: 'Nepal',
    },
    {
        code: 528,
        alpha2: 'NL',
        alpha3: 'NLD',
        nom_fr_fr: 'Pays-Bas',
        nom_en_gb: 'Netherlands',
    },
    {
        code: 530,
        alpha2: 'AN',
        alpha3: 'ANT',
        nom_fr_fr: 'Antilles Néerlandaises',
        nom_en_gb: 'Netherlands Antilles',
    },
    {
        code: 533,
        alpha2: 'AW',
        alpha3: 'ABW',
        nom_fr_fr: 'Aruba',
        nom_en_gb: 'Aruba',
    },
    {
        code: 540,
        alpha2: 'NC',
        alpha3: 'NCL',
        nom_fr_fr: 'Nouvelle-Calédonie',
        nom_en_gb: 'New Caledonia',
    },
    {
        code: 548,
        alpha2: 'VU',
        alpha3: 'VUT',
        nom_fr_fr: 'Vanuatu',
        nom_en_gb: 'Vanuatu',
    },
    {
        code: 554,
        alpha2: 'NZ',
        alpha3: 'NZL',
        nom_fr_fr: 'Nouvelle-Zélande',
        nom_en_gb: 'New Zealand',
    },
    {
        code: 558,
        alpha2: 'NI',
        alpha3: 'NIC',
        nom_fr_fr: 'Nicaragua',
        nom_en_gb: 'Nicaragua',
    },
    {
        code: 562,
        alpha2: 'NE',
        alpha3: 'NER',
        nom_fr_fr: 'Niger',
        nom_en_gb: 'Niger',
    },
    {
        code: 566,
        alpha2: 'NG',
        alpha3: 'NGA',
        nom_fr_fr: 'Nigéria',
        nom_en_gb: 'Nigeria',
    },
    {
        code: 570,
        alpha2: 'NU',
        alpha3: 'NIU',
        nom_fr_fr: 'Niué',
        nom_en_gb: 'Niue',
    },
    {
        code: 574,
        alpha2: 'NF',
        alpha3: 'NFK',
        nom_fr_fr: 'Île Norfolk',
        nom_en_gb: 'Norfolk Island',
    },
    {
        code: 578,
        alpha2: 'NO',
        alpha3: 'NOR',
        nom_fr_fr: 'Norvège',
        nom_en_gb: 'Norway',
    },
    {
        code: 580,
        alpha2: 'MP',
        alpha3: 'MNP',
        nom_fr_fr: 'Îles Mariannes du Nord',
        nom_en_gb: 'Northern Mariana Islands',
    },
    {
        code: 581,
        alpha2: 'UM',
        alpha3: 'UMI',
        nom_fr_fr: 'Îles Mineures Éloignées des États-Unis',
        nom_en_gb: 'United States Minor Outlying Islands',
    },
    {
        code: 583,
        alpha2: 'FM',
        alpha3: 'FSM',
        nom_fr_fr: 'États Fédérés de Micronésie',
        nom_en_gb: 'Federated States of Micronesia',
    },
    {
        code: 584,
        alpha2: 'MH',
        alpha3: 'MHL',
        nom_fr_fr: 'Îles Marshall',
        nom_en_gb: 'Marshall Islands',
    },
    {
        code: 585,
        alpha2: 'PW',
        alpha3: 'PLW',
        nom_fr_fr: 'Palaos',
        nom_en_gb: 'Palau',
    },
    {
        code: 586,
        alpha2: 'PK',
        alpha3: 'PAK',
        nom_fr_fr: 'Pakistan',
        nom_en_gb: 'Pakistan',
    },
    {
        code: 591,
        alpha2: 'PA',
        alpha3: 'PAN',
        nom_fr_fr: 'Panama',
        nom_en_gb: 'Panama',
    },
    {
        code: 598,
        alpha2: 'PG',
        alpha3: 'PNG',
        nom_fr_fr: 'Papouasie-Nouvelle-Guinée',
        nom_en_gb: 'Papua New Guinea',
    },
    {
        code: 600,
        alpha2: 'PY',
        alpha3: 'PRY',
        nom_fr_fr: 'Paraguay',
        nom_en_gb: 'Paraguay',
    },
    {
        code: 604,
        alpha2: 'PE',
        alpha3: 'PER',
        nom_fr_fr: 'Pérou',
        nom_en_gb: 'Peru',
    },
    {
        code: 608,
        alpha2: 'PH',
        alpha3: 'PHL',
        nom_fr_fr: 'Philippines',
        nom_en_gb: 'Philippines',
    },
    {
        code: 612,
        alpha2: 'PN',
        alpha3: 'PCN',
        nom_fr_fr: 'Pitcairn',
        nom_en_gb: 'Pitcairn',
    },
    {
        code: 616,
        alpha2: 'PL',
        alpha3: 'POL',
        nom_fr_fr: 'Pologne',
        nom_en_gb: 'Poland',
    },
    {
        code: 620,
        alpha2: 'PT',
        alpha3: 'PRT',
        nom_fr_fr: 'Portugal',
        nom_en_gb: 'Portugal',
    },
    {
        code: 624,
        alpha2: 'GW',
        alpha3: 'GNB',
        nom_fr_fr: 'Guinée-Bissau',
        nom_en_gb: 'Guinea-Bissau',
    },
    {
        code: 626,
        alpha2: 'TL',
        alpha3: 'TLS',
        nom_fr_fr: 'Timor-Leste',
        nom_en_gb: 'Timor-Leste',
    },
    {
        code: 630,
        alpha2: 'PR',
        alpha3: 'PRI',
        nom_fr_fr: 'Porto Rico',
        nom_en_gb: 'Puerto Rico',
    },
    {
        code: 634,
        alpha2: 'QA',
        alpha3: 'QAT',
        nom_fr_fr: 'Qatar',
        nom_en_gb: 'Qatar',
    },
    {
        code: 638,
        alpha2: 'RE',
        alpha3: 'REU',
        nom_fr_fr: 'Réunion',
        nom_en_gb: 'Réunion',
    },
    {
        code: 642,
        alpha2: 'RO',
        alpha3: 'ROU',
        nom_fr_fr: 'Roumanie',
        nom_en_gb: 'Romania',
    },
    {
        code: 643,
        alpha2: 'RU',
        alpha3: 'RUS',
        nom_fr_fr: 'Fédération de Russie',
        nom_en_gb: 'Russian Federation',
    },
    {
        code: 646,
        alpha2: 'RW',
        alpha3: 'RWA',
        nom_fr_fr: 'Rwanda',
        nom_en_gb: 'Rwanda',
    },
    {
        code: 654,
        alpha2: 'SH',
        alpha3: 'SHN',
        nom_fr_fr: 'Sainte-Hélène',
        nom_en_gb: 'Saint Helena',
    },
    {
        code: 659,
        alpha2: 'KN',
        alpha3: 'KNA',
        nom_fr_fr: 'Saint-Kitts-et-Nevis',
        nom_en_gb: 'Saint Kitts and Nevis',
    },
    {
        code: 660,
        alpha2: 'AI',
        alpha3: 'AIA',
        nom_fr_fr: 'Anguilla',
        nom_en_gb: 'Anguilla',
    },
    {
        code: 662,
        alpha2: 'LC',
        alpha3: 'LCA',
        nom_fr_fr: 'Sainte-Lucie',
        nom_en_gb: 'Saint Lucia',
    },
    {
        code: 666,
        alpha2: 'PM',
        alpha3: 'SPM',
        nom_fr_fr: 'Saint-Pierre-et-Miquelon',
        nom_en_gb: 'Saint-Pierre and Miquelon',
    },
    {
        code: 670,
        alpha2: 'VC',
        alpha3: 'VCT',
        nom_fr_fr: 'Saint-Vincent-et-les Grenadines',
        nom_en_gb: 'Saint Vincent and the Grenadines',
    },
    {
        code: 674,
        alpha2: 'SM',
        alpha3: 'SMR',
        nom_fr_fr: 'Saint-Marin',
        nom_en_gb: 'San Marino',
    },
    {
        code: 678,
        alpha2: 'ST',
        alpha3: 'STP',
        nom_fr_fr: 'Sao Tomé-et-Principe',
        nom_en_gb: 'Sao Tome and Principe',
    },
    {
        code: 682,
        alpha2: 'SA',
        alpha3: 'SAU',
        nom_fr_fr: 'Arabie Saoudite',
        nom_en_gb: 'Saudi Arabia',
    },
    {
        code: 686,
        alpha2: 'SN',
        alpha3: 'SEN',
        nom_fr_fr: 'Sénégal',
        nom_en_gb: 'Senegal',
    },
    {
        code: 690,
        alpha2: 'SC',
        alpha3: 'SYC',
        nom_fr_fr: 'Seychelles',
        nom_en_gb: 'Seychelles',
    },
    {
        code: 694,
        alpha2: 'SL',
        alpha3: 'SLE',
        nom_fr_fr: 'Sierra Leone',
        nom_en_gb: 'Sierra Leone',
    },
    {
        code: 702,
        alpha2: 'SG',
        alpha3: 'SGP',
        nom_fr_fr: 'Singapour',
        nom_en_gb: 'Singapore',
    },
    {
        code: 703,
        alpha2: 'SK',
        alpha3: 'SVK',
        nom_fr_fr: 'Slovaquie',
        nom_en_gb: 'Slovakia',
    },
    {
        code: 704,
        alpha2: 'VN',
        alpha3: 'VNM',
        nom_fr_fr: 'Viet Nam',
        nom_en_gb: 'Vietnam',
    },
    {
        code: 705,
        alpha2: 'SI',
        alpha3: 'SVN',
        nom_fr_fr: 'Slovénie',
        nom_en_gb: 'Slovenia',
    },
    {
        code: 706,
        alpha2: 'SO',
        alpha3: 'SOM',
        nom_fr_fr: 'Somalie',
        nom_en_gb: 'Somalia',
    },
    {
        code: 710,
        alpha2: 'ZA',
        alpha3: 'ZAF',
        nom_fr_fr: 'Afrique du Sud',
        nom_en_gb: 'South Africa',
    },
    {
        code: 716,
        alpha2: 'ZW',
        alpha3: 'ZWE',
        nom_fr_fr: 'Zimbabwe',
        nom_en_gb: 'Zimbabwe',
    },
    {
        code: 724,
        alpha2: 'ES',
        alpha3: 'ESP',
        nom_fr_fr: 'Espagne',
        nom_en_gb: 'Spain',
    },
    {
        code: 732,
        alpha2: 'EH',
        alpha3: 'ESH',
        nom_fr_fr: 'Sahara Occidental',
        nom_en_gb: 'Western Sahara',
    },
    {
        code: 736,
        alpha2: 'SD',
        alpha3: 'SDN',
        nom_fr_fr: 'Soudan',
        nom_en_gb: 'Sudan',
    },
    {
        code: 740,
        alpha2: 'SR',
        alpha3: 'SUR',
        nom_fr_fr: 'Suriname',
        nom_en_gb: 'Suriname',
    },
    {
        code: 744,
        alpha2: 'SJ',
        alpha3: 'SJM',
        nom_fr_fr: 'Svalbard etÎle Jan Mayen',
        nom_en_gb: 'Svalbard and Jan Mayen',
    },
    {
        code: 748,
        alpha2: 'SZ',
        alpha3: 'SWZ',
        nom_fr_fr: 'Swaziland',
        nom_en_gb: 'Swaziland',
    },
    {
        code: 752,
        alpha2: 'SE',
        alpha3: 'SWE',
        nom_fr_fr: 'Suède',
        nom_en_gb: 'Sweden',
    },
    {
        code: 756,
        alpha2: 'CH',
        alpha3: 'CHE',
        nom_fr_fr: 'Suisse',
        nom_en_gb: 'Switzerland',
    },
    {
        code: 760,
        alpha2: 'SY',
        alpha3: 'SYR',
        nom_fr_fr: 'République Arabe Syrienne',
        nom_en_gb: 'Syrian Arab Republic',
    },
    {
        code: 762,
        alpha2: 'TJ',
        alpha3: 'TJK',
        nom_fr_fr: 'Tadjikistan',
        nom_en_gb: 'Tajikistan',
    },
    {
        code: 764,
        alpha2: 'TH',
        alpha3: 'THA',
        nom_fr_fr: 'Thaïlande',
        nom_en_gb: 'Thailand',
    },
    {
        code: 768,
        alpha2: 'TG',
        alpha3: 'TGO',
        nom_fr_fr: 'Togo',
        nom_en_gb: 'Togo',
    },
    {
        code: 772,
        alpha2: 'TK',
        alpha3: 'TKL',
        nom_fr_fr: 'Tokelau',
        nom_en_gb: 'Tokelau',
    },
    {
        code: 776,
        alpha2: 'TO',
        alpha3: 'TON',
        nom_fr_fr: 'Tonga',
        nom_en_gb: 'Tonga',
    },
    {
        code: 780,
        alpha2: 'TT',
        alpha3: 'TTO',
        nom_fr_fr: 'Trinité-et-Tobago',
        nom_en_gb: 'Trinidad and Tobago',
    },
    {
        code: 784,
        alpha2: 'AE',
        alpha3: 'ARE',
        nom_fr_fr: 'Émirats Arabes Unis',
        nom_en_gb: 'United Arab Emirates',
    },
    {
        code: 788,
        alpha2: 'TN',
        alpha3: 'TUN',
        nom_fr_fr: 'Tunisie',
        nom_en_gb: 'Tunisia',
    },
    {
        code: 792,
        alpha2: 'TR',
        alpha3: 'TUR',
        nom_fr_fr: 'Turquie',
        nom_en_gb: 'Turkey',
    },
    {
        code: 795,
        alpha2: 'TM',
        alpha3: 'TKM',
        nom_fr_fr: 'Turkménistan',
        nom_en_gb: 'Turkmenistan',
    },
    {
        code: 796,
        alpha2: 'TC',
        alpha3: 'TCA',
        nom_fr_fr: 'Îles Turks et Caïques',
        nom_en_gb: 'Turks and Caicos Islands',
    },
    {
        code: 798,
        alpha2: 'TV',
        alpha3: 'TUV',
        nom_fr_fr: 'Tuvalu',
        nom_en_gb: 'Tuvalu',
    },
    {
        code: 800,
        alpha2: 'UG',
        alpha3: 'UGA',
        nom_fr_fr: 'Ouganda',
        nom_en_gb: 'Uganda',
    },
    {
        code: 804,
        alpha2: 'UA',
        alpha3: 'UKR',
        nom_fr_fr: 'Ukraine',
        nom_en_gb: 'Ukraine',
    },
    {
        code: 807,
        alpha2: 'MK',
        alpha3: 'MKD',
        nom_fr_fr: "L'ex-République Yougoslave de Macédoine",
        nom_en_gb: 'The Former Yugoslav Republic of Macedonia',
    },
    {
        code: 818,
        alpha2: 'EG',
        alpha3: 'EGY',
        nom_fr_fr: 'Égypte',
        nom_en_gb: 'Egypt',
    },
    {
        code: 826,
        alpha2: 'GB',
        alpha3: 'GBR',
        nom_fr_fr: 'Royaume-Uni',
        nom_en_gb: 'United Kingdom',
    },
    {
        code: 833,
        alpha2: 'IM',
        alpha3: 'IMN',
        nom_fr_fr: 'Île de Man',
        nom_en_gb: 'Isle of Man',
    },
    {
        code: 834,
        alpha2: 'TZ',
        alpha3: 'TZA',
        nom_fr_fr: 'République-Unie de Tanzanie',
        nom_en_gb: 'United Republic Of Tanzania',
    },
    {
        code: 840,
        alpha2: 'US',
        alpha3: 'USA',
        nom_fr_fr: 'États-Unis',
        nom_en_gb: 'United States',
    },
    {
        code: 850,
        alpha2: 'VI',
        alpha3: 'VIR',
        nom_fr_fr: 'Îles Vierges des États-Unis',
        nom_en_gb: 'U.S. Virgin Islands',
    },
    {
        code: 854,
        alpha2: 'BF',
        alpha3: 'BFA',
        nom_fr_fr: 'Burkina Faso',
        nom_en_gb: 'Burkina Faso',
    },
    {
        code: 858,
        alpha2: 'UY',
        alpha3: 'URY',
        nom_fr_fr: 'Uruguay',
        nom_en_gb: 'Uruguay',
    },
    {
        code: 860,
        alpha2: 'UZ',
        alpha3: 'UZB',
        nom_fr_fr: 'Ouzbékistan',
        nom_en_gb: 'Uzbekistan',
    },
    {
        code: 862,
        alpha2: 'VE',
        alpha3: 'VEN',
        nom_fr_fr: 'Venezuela',
        nom_en_gb: 'Venezuela',
    },
    {
        code: 876,
        alpha2: 'WF',
        alpha3: 'WLF',
        nom_fr_fr: 'Wallis et Futuna',
        nom_en_gb: 'Wallis and Futuna',
    },
    {
        code: 882,
        alpha2: 'WS',
        alpha3: 'WSM',
        nom_fr_fr: 'Samoa',
        nom_en_gb: 'Samoa',
    },
    {
        code: 887,
        alpha2: 'YE',
        alpha3: 'YEM',
        nom_fr_fr: 'Yémen',
        nom_en_gb: 'Yemen',
    },
    {
        code: 891,
        alpha2: 'CS',
        alpha3: 'SCG',
        nom_fr_fr: 'Serbie-et-Monténégro',
        nom_en_gb: 'Serbia and Montenegro',
    },
    {
        code: 894,
        alpha2: 'ZM',
        alpha3: 'ZMB',
        nom_fr_fr: 'Zambie',
        nom_en_gb: 'Zambia',
    },
]
