import axiosInstance from './AxiosConfiguration'
import type DemandeFormulaire from '../constants/DemandeFormulaire'

const FormulaireService = {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    envoieDonneesFormulaire: async (demandeFormulaire: DemandeFormulaire | undefined): Promise<any> => {
        try {
            const response = await axiosInstance.post('/envoyer-formulaire', demandeFormulaire)

            return response.data
        } catch (error) {
            console.error("Erreur lors de l'envoi des données :", error)
            throw error
        }
    },
}

export default FormulaireService
