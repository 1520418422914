import React from 'react'
import {Row, Tile, TileBody, TileContent, TileDetail, TileTitle, Typography} from '@design-system-etat/dsfr-react'
import {genererAdresseComplete, genererAdresseEtrangereComplete} from '../../../constants/FonctionCommunes'
import type DonneesPersonne from '../../../constants/DonneesPersonne'
import {useEvenementContext} from '../../../contexts/EvenementContext'

interface Props {
    informationsPersonnelles: DonneesPersonne
    listePersonnesRecherchees: DonneesPersonne[]
}

const Recapitulatif: React.FC<Props> = ({informationsPersonnelles, listePersonnesRecherchees}) => {
    const {evenements} = useEvenementContext()

    function recupererEventIntituleParId(idEvent: number | undefined): string | undefined {
        return evenements.find((event) => event.idEvent === idEvent)?.intitule
    }

    return (
        <>
            <Row style={{alignItems: 'center'}}>
                <Typography component="h3" className={'fr-mr-2w'}>
                    Evénement:{' '}
                </Typography>
                <Typography>{recupererEventIntituleParId(informationsPersonnelles.evenement)}</Typography>
            </Row>
            <Row>
                <h2>Vos données personnelles</h2>
            </Row>
            <Row>
                <div className={'tuile-bloc-recapitulatif'}>
                    <Tile enlargeLink={false} size={'md'}>
                        <TileBody>
                            <TileContent className={'tuile-contenu'}>
                                <TileDetail className={'tuile-detail-bloc-recapitulatif'}>
                                    <div>
                                        <strong>Civilité&nbsp;:</strong> {informationsPersonnelles?.civilite}
                                    </div>
                                    <div>
                                        <strong>Identité&nbsp;:</strong>{' '}
                                        {informationsPersonnelles?.prenom +
                                            ' ' +
                                            informationsPersonnelles?.nomNaissance}
                                    </div>
                                    <div>
                                        <strong>Téléphone&nbsp;:</strong> {informationsPersonnelles?.telephone}
                                    </div>
                                    <div>
                                        <span>
                                            <strong>Courriel&nbsp;:</strong> {informationsPersonnelles?.email}
                                        </span>
                                    </div>
                                    <div>
                                        <strong>Nationalité&nbsp;:</strong>{' '}
                                        {informationsPersonnelles?.nationalite +
                                            (informationsPersonnelles?.nationaliteDeux
                                                ? ' / ' + informationsPersonnelles.nationaliteDeux
                                                : '')}
                                    </div>
                                    <div>
                                        <strong>Adresse&nbsp;:</strong>{' '}
                                        {informationsPersonnelles?.adresseEtrangere
                                            ? genererAdresseEtrangereComplete(informationsPersonnelles)
                                            : genererAdresseComplete(informationsPersonnelles)}
                                    </div>
                                </TileDetail>
                            </TileContent>
                        </TileBody>
                    </Tile>
                </div>
            </Row>
            <Row>
                <Row className={'fr-mt-2w'}>
                    <h2>{listePersonnesRecherchees.length === 1 ? 'Personne recherchée' : 'Personnes recherchées'}</h2>
                </Row>
            </Row>
            {listePersonnesRecherchees.length > 0 && (
                <Row className={'fr-mb-2w'}>
                    {listePersonnesRecherchees.map((personneRecherchee, index) => (
                        <div className={'tuile-bloc-recapitulatif'} key={index}>
                            <Tile enlargeLink={false} size={'md'}>
                                <TileBody>
                                    <TileContent className={'tuile-contenu'}>
                                        <TileTitle as="h3">personne recherchée {index + 1}</TileTitle>
                                        <TileDetail className={'tuile-detail-bloc-recapitulatif'}>
                                            <div>
                                                <strong>Civilité&nbsp;:</strong> {personneRecherchee?.civilite}
                                            </div>
                                            <div>
                                                <strong>Identité&nbsp;:</strong>{' '}
                                                {personneRecherchee?.prenom + ' ' + personneRecherchee?.nomNaissance}
                                            </div>
                                            <div>
                                                <strong>Téléphone&nbsp;:</strong> {personneRecherchee?.telephone}
                                            </div>
                                            <div>
                                                <strong>Courriel&nbsp;:</strong> {personneRecherchee?.email}
                                            </div>
                                            <div>
                                                <strong>Nationalité&nbsp;:</strong>{' '}
                                                {personneRecherchee?.nationalite +
                                                    (personneRecherchee?.nationaliteDeux
                                                        ? ' / ' + personneRecherchee.nationaliteDeux
                                                        : '')}
                                            </div>
                                            <div>
                                                <strong>Adresse&nbsp;:</strong>{' '}
                                                {personneRecherchee?.adresseEtrangere
                                                    ? genererAdresseEtrangereComplete(personneRecherchee)
                                                    : genererAdresseComplete(personneRecherchee)}
                                            </div>
                                        </TileDetail>
                                    </TileContent>
                                </TileBody>
                            </Tile>
                        </div>
                    ))}
                </Row>
            )}
        </>
    )
}

export default Recapitulatif
