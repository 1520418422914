import React from 'react'
import {HeaderLogo, HeaderMi, HeaderServiceMi, Logo} from '@design-system-etat/dsfr-react'
import {NavLink} from 'react-router-dom'
import {URLPageEnum} from '../../constants/URLPageEnum'

const Header: React.FC = () => (
    <HeaderMi>
        <HeaderLogo>
            <NavLink to={URLPageEnum.ACCUEIL}>
                <Logo>
                    <>
                        république
                        <br />
                        française
                    </>
                </Logo>
            </NavLink>
        </HeaderLogo>
        <HeaderServiceMi titre="INFORMATION DU PUBLIC ET AIDE AUX VICTIMES" />
    </HeaderMi>
)

export default Header
