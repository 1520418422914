import type DonneesPersonne from './DonneesPersonne'

export const genererAdresseComplete = (personne: DonneesPersonne): string => {
    return `${personne.numero || ''} ${personne.complement || ''} ${personne.voie || ''} ${personne.nomVoie || ''} ${personne.codePostal || ''} ${personne.ville || ''}`.trim()
}

export const genererAdresseEtrangereComplete = (personne: DonneesPersonne): string => {
    return `${personne.rueEtrangere || ''} ${personne.codePostal || ''} ${personne.ville || ''}`.trim()
}

export const maskTelephone = (telephone: string | null | undefined): string => {
    if (!telephone) return ''

    const cleaned = telephone.replace(/(?!^\+)[^\d]/g, '')

    if (/^\d{10}$/.test(cleaned)) {
        return cleaned.replace(/(\d{2})(?=\d)/g, '$1 ')
    }

    const limited = cleaned.slice(0, 16)
    return limited.replace(/(\d{2,3})(?=\d)/g, '$1 ')
}

export const estAdresseValide = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export function retourDateDujour() {
    const dateDuJour = new Date()

    return formatDate(dateDuJour)
}

export function retireEspacement(valeur: string) {
    return valeur.replace(/\s+/g, '')
}
