import React, {useState} from 'react'
import {
    Artwork,
    Col,
    Container,
    Link,
    Row,
    Tile,
    TileBody,
    TileContent,
    TileDesc,
    TileHeader,
    TilePictogram,
    TileTitle,
} from '@design-system-etat/dsfr-react'
import search from '@gouvfr/dsfr/dist/artwork/pictograms/digital/search.svg'
import {FormProvider, useForm} from 'react-hook-form'
import ModaleMail from '../verificationMail/ModaleMail'
import {useNavigate} from 'react-router'
import type DonneesPersonne from '../../constants/DonneesPersonne'

const Accueil: React.FC = () => {
    const [adresseValide, setAdresseValide] = useState<boolean>(false)
    const navigate = useNavigate()

    const form = useForm<DonneesPersonne>({
        defaultValues: {
            evenement: undefined,
            civilite: '',
            nomNaissance: '',
            nomUsage: '',
            prenom: '',
            dateNaissance: '',
            nationalite: '',
            nationaliteDeux: '',
            telephone: '',
            email: '',
            confirmationEmail: '',
            adresseEtrangere: false,
            numero: undefined,
            complement: '',
            voie: '',
            nomVoie: '',
            residence: '',
            immeuble: '',
            etage: '',
            appartement: '',
            codePostal: undefined,
            ville: '',
            cedex: '',
            rueEtrangere: '',
            paysEtranger: '',
        },
    })

    return (
        <Container className="fr-my-8v">
            <FormProvider {...form}>
                <Row>
                    <Col col={12} md={6} xl={4}>
                        <Tile onClick={() => setAdresseValide(true)}>
                            <TileBody>
                                <TileContent>
                                    <TileTitle as="h1">
                                        <Link as={'button'} onClick={() => setAdresseValide(true)}>
                                            Rechercher une personne
                                        </Link>
                                    </TileTitle>
                                    <TileDesc>
                                        Renseigner vos informations et celles de la personne que vous recherchez
                                    </TileDesc>
                                </TileContent>
                            </TileBody>
                            <TileHeader>
                                <TilePictogram>
                                    <Artwork svg={search} size="80" />
                                </TilePictogram>
                            </TileHeader>
                        </Tile>
                    </Col>
                </Row>
                {adresseValide && (
                    <ModaleMail
                        onClose={() => setAdresseValide(false)}
                        onValidate={(email) => navigate('/recherche-personne', {state: {email: email}})}
                    />
                )}
            </FormProvider>
        </Container>
    )
}

export default Accueil
