import React, {useContext, useState} from 'react'

interface LoadingContextType {
    loading: boolean
    setLoading: (loading: boolean) => void
}

export const LoadingContext = React.createContext<null | LoadingContextType>(null)

export function useLoadingContext() {
    const contexte = useContext(LoadingContext)
    if (!contexte) {
        throw new Error('useLoadingContext doit être utilisé dans un LoadingContextProvider')
    }
    return contexte
}
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function LoadingContextProvider(props: Readonly<Record<string, any>>) {
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <LoadingContext.Provider
            value={{
                loading,
                setLoading,
            }}
            {...props}
        />
    )
}
