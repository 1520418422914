import React, {useContext, useEffect, useState} from 'react'
import type Evenement from '../types/Evenement'
import EvenementService from '../services/EvenementService'
import {useLoadingContext} from './LoadingContext'

interface EvenementContextType {
    evenements: Evenement[]
}

export const EvenementContext = React.createContext<null | EvenementContextType>(null)

export function useEvenementContext() {
    const contexte = useContext(EvenementContext)
    if (!contexte) {
        throw new Error('useEvenementContext doit être utilisé dans un EvenementContextProvider')
    }
    return contexte
}
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function EvenementContextProvider(props: Readonly<Record<string, any>>) {
    const [evenements, setEvenements] = useState<Evenement[]>([])

    const {setLoading} = useLoadingContext()

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                const evenements = await EvenementService.recupererListeEvenements()
                setEvenements(evenements || [])
            } catch (error) {
                console.error('Failed to fetch events:', error)
                setEvenements([])
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [setLoading])

    return (
        <EvenementContext.Provider
            value={{
                evenements,
            }}
            {...props}
        />
    )
}
