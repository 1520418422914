import React, {useState} from 'react'
import {Button, ButtonGroup, InputControl} from '@design-system-etat/dsfr-react'
import {validateFormField} from '../../constants/ReakHookForm'
import {MESSAGE_ERREUR_CHAMP_INCOMPLET} from '../../constants/MessagesErreurs'
import {useForm, useFormContext} from 'react-hook-form'
import axiosInstance from '../../services/AxiosConfiguration'

interface DonneesDeVerification {
    code: string
}

interface Props {
    onClose: () => void
    onValidate: () => void
}

const FormulaireVerificationCode: React.FC<Props> = ({onClose, onValidate}) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const {watch} = useFormContext()

    const formulaireDeVerification = useForm<DonneesDeVerification>({
        mode: 'onChange',
        defaultValues: {
            code: '',
        },
    })

    const verifierCode = async (data: DonneesDeVerification) => {
        if (!data.code) return
        setErrorMessage(null)

        try {
            await axiosInstance.post('/formulaire-web/verifier-code', {
                email: watch('email'),
                code: data.code,
            })

            onValidate()
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            console.error('Erreur lors de la vérification du code :', error.response)

            const errorMessage = 'Une erreur est survenue lors de la vérification du code.'

            setErrorMessage(error.response ? error.response.data?.toString('UTF-8') : errorMessage)
        }
    }

    return (
        <form>
            <InputControl
                label="Code"
                id="code"
                message={formulaireDeVerification.formState.errors.code?.message as string}
                state={validateFormField(formulaireDeVerification.formState.errors.code)}
                {...formulaireDeVerification.register('code', {
                    required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                })}
            />
            {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
            <ButtonGroup align="right" iconPosition="left" inline="lg">
                <Button variant="secondary" type="button" onClick={onClose}>
                    Annuler
                </Button>
                <Button variant="primary" type="button" onClick={formulaireDeVerification.handleSubmit(verifierCode)}>
                    Valider
                </Button>
            </ButtonGroup>
        </form>
    )
}

export default FormulaireVerificationCode
