import React from 'react'
import {LoaderMi} from '@design-system-etat/dsfr-react'
import './Loader.css'
import {useLoadingContext} from '../contexts/LoadingContext'

const Loader: React.FC = () => {
    const {loading} = useLoadingContext()

    return (
        <>
            {loading && (
                <div className={'loader-plein-ecran'}>
                    <LoaderMi taille="lg" />
                </div>
            )}
        </>
    )
}

export default Loader
