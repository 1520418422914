import React, {useEffect, useState} from 'react'
import {
    Alert,
    AlertDescription,
    AlertTitle,
    Button,
    ButtonGroup,
    Col,
    Container,
    IndicateurEtapes,
    Row,
} from '@design-system-etat/dsfr-react'
import SelectionEvenement from './components/SelectionEvenement'
import InformationsPersonnelles from './components/InformationsPersonnelles'
import PersonnesRecherchees from './components/personnesRecherchees/PersonnesRecherchees'
import Recapitulatif from './components/Recapitulatif'
import {useLocation, useNavigate} from 'react-router'
import {FormProvider, useForm} from 'react-hook-form'
import ModaleAvertissement from '../../components/ModaleAvertissement'
import formulaireService from '../../services/FormulaireService'
import type DonneesPersonne from '../../constants/DonneesPersonne'
import type {Personne} from '../../constants/DemandeFormulaire'
import type DemandeFormulaire from '../../constants/DemandeFormulaire'
import {retireEspacement} from '../../constants/FonctionCommunes'

const RecherchePersonne: React.FC = () => {
    const [indiceEtapeEnCours, setIndiceEtapeEnCours] = useState(0)
    const [listePersonnesRecherchees, setListePersonnesRecherchees] = useState<DonneesPersonne[]>([])
    const [informationsPersonnelle, setInformationsPersonnelle] = useState<DonneesPersonne>()
    const [donneesFormulaire, setDonneesFormulaire] = useState<DemandeFormulaire>()
    const [afficherModaleAvertissement, setAfficherModaleAvertissement] = useState<boolean>(false)
    const [afficherAlerte, setAfficherAlerte] = useState<boolean>(false)
    const [afficherEnvoieSucces, setAfficherEnvoieSucces] = useState<boolean>(false)
    const [afficherEnvoieEchec, setAfficherEnvoieEchec] = useState<boolean>(false)
    const [afficherModaleValidation, setAfficherModaleValidation] = useState<boolean>(false)
    const [isRedirecting, setIsRedirecting] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const email = location.state?.email

    const listeEtapes = [
        {
            titre: "Sélection de l'événement",
            composant: <SelectionEvenement />,
        },
        {
            titre: 'Vos informations personnelles',
            composant: <InformationsPersonnelles />,
        },
        {
            titre: 'Personne(s) recherchée(s)',
            composant: (
                <PersonnesRecherchees
                    listePersonnesRecherchees={listePersonnesRecherchees}
                    setListePersonnesRecherchees={(listePersonnes) => {
                        setListePersonnesRecherchees(listePersonnes)
                        setAfficherAlerte(false)
                    }}
                />
            ),
        },
        {
            titre: 'Récapitulatif',
            composant: (
                <Recapitulatif
                    informationsPersonnelles={informationsPersonnelle as DonneesPersonne}
                    listePersonnesRecherchees={listePersonnesRecherchees}
                />
            ),
        },
    ]

    const etapeEnCours = listeEtapes[indiceEtapeEnCours]
    const estDerniereEtape = indiceEtapeEnCours === listeEtapes.length - 1
    const estPremiereEtape = indiceEtapeEnCours === 0

    const precedent = () => {
        setAfficherAlerte(false)
        setIndiceEtapeEnCours((indiceEtapeEnCours) => indiceEtapeEnCours - 1)
    }

    const form = useForm<DonneesPersonne>({
        defaultValues: {
            evenement: undefined,
            civilite: '',
            nomNaissance: '',
            nomUsage: '',
            prenom: '',
            dateNaissance: '',
            nationalite: '',
            nationaliteDeux: '',
            telephone: '',
            email: email,
            confirmationEmail: '',
            adresseEtrangere: false,
            numero: undefined,
            complement: '',
            voie: '',
            nomVoie: '',
            residence: '',
            immeuble: '',
            etage: '',
            appartement: '',
            codePostal: undefined,
            ville: '',
            cedex: '',
            rueEtrangere: '',
        },
    })

    const soumettreFormulaire = (data: DonneesPersonne) => {
        if (estDerniereEtape && informationsPersonnelle) {
            const convertToPersonne = (donnees: DonneesPersonne): Personne => ({
                id: donnees.id,
                civilite: donnees.civilite,
                nomNaissance: donnees.nomNaissance,
                nomUsage: donnees.nomUsage,
                prenom: donnees.prenom,
                dateNaissance: donnees.dateNaissance,
                nationalite: donnees.nationalite,
                nationaliteDeux: donnees.nationaliteDeux,
                telephone: retireEspacement(donnees.telephone),
                email: donnees.email,
                confirmationEmail: donnees.confirmationEmail,
                adresse: {
                    numero: donnees.numero,
                    complement: donnees.complement,
                    voie: donnees.voie,
                    nomVoie: donnees.nomVoie,
                    residence: donnees.residence,
                    immeuble: donnees.immeuble,
                    etage: donnees.etage,
                    appartement: donnees.appartement,
                    codePostal: donnees.codePostal,
                    ville: donnees.ville,
                    cedex: donnees.cedex,
                    adresseEtrangere: donnees.adresseEtrangere,
                    rueEtrangere: donnees.rueEtrangere,
                    paysEtranger: donnees.paysEtranger,
                },
            })

            const formulaire: DemandeFormulaire = {
                idEvent: informationsPersonnelle.evenement || 0,
                appelant: convertToPersonne(informationsPersonnelle),
                victimes: listePersonnesRecherchees.map(convertToPersonne),
            }
            setDonneesFormulaire(formulaire)
            setAfficherModaleValidation(true)
        } else if (indiceEtapeEnCours === 2 && listePersonnesRecherchees?.length === 0) {
            setAfficherAlerte(true)
        } else {
            setInformationsPersonnelle(data)
            setIndiceEtapeEnCours((indiceEtapeEnCours) => indiceEtapeEnCours + 1)
        }
        // Envoyer `formData` à l'API ou traiter la soumission ici
    }

    useEffect(() => {
        if (!location.state) {
            setIsRedirecting(true)
            setTimeout(() => {
                navigate('/')
            }, 3000)
        }
    }, [])

    useEffect(() => {
        if (afficherEnvoieSucces) {
            const timeout = setTimeout(() => {
                navigate('/')
            }, 500)

            return () => clearTimeout(timeout) // Nettoyage pour éviter des effets indésirables
        }
    }, [afficherEnvoieSucces])

    return (
        <Container className="fr-my-8v">
            {isRedirecting && (
                <Alert severity="warning" size="md">
                    <AlertTitle>Attention</AlertTitle>
                    <AlertDescription>
                        Vous n'êtes pas autorisé à accéder à cette page sans avoir d'informations valides. Vous allez
                        être redirigé vers la page d'accueil dans quelques secondes.
                    </AlertDescription>
                </Alert>
            )}
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(soumettreFormulaire)}>
                    <h1>{etapeEnCours.titre}</h1>
                    {etapeEnCours.composant}
                    <Row className="fr-mt-2w">
                        <Col col={12} md={8}>
                            <IndicateurEtapes
                                titreEtapeEnCours={etapeEnCours.titre}
                                titreProchaineEtape={
                                    !estDerniereEtape ? listeEtapes[indiceEtapeEnCours + 1].titre : undefined
                                }
                                etapeEnCours={indiceEtapeEnCours + 1}
                                nombreEtapes={listeEtapes.length}
                            />
                        </Col>
                        <Col col={12} md={4} className="fr-mt-md-6w">
                            <ButtonGroup inline="md" align="right">
                                <Button
                                    variant="secondary"
                                    type={'button'}
                                    onClick={() => setAfficherModaleAvertissement(true)}
                                >
                                    Annuler
                                </Button>
                                {!estPremiereEtape && (
                                    <Button variant="primary" type={'button'} onClick={precedent}>
                                        Précédent
                                    </Button>
                                )}

                                <Button type={'submit'}>{estDerniereEtape ? 'Valider' : 'Suivant'}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    {afficherModaleAvertissement && (
                        <ModaleAvertissement
                            content={
                                'Aucunes informations ne seront enregistrées.\n' +
                                'Etes-vous sûr de vouloir tout annuler ? \n'
                            }
                            onClose={() => setAfficherModaleAvertissement(false)}
                            onValidate={() => navigate('/')}
                        />
                    )}
                    {afficherAlerte && (
                        <Alert severity="error" size="md">
                            <AlertTitle>Erreur</AlertTitle>
                            <AlertDescription>Veuillez au moins saisir une personne recherchée</AlertDescription>
                        </Alert>
                    )}
                    {afficherModaleValidation && (
                        <ModaleAvertissement
                            content={
                                'Vos informations seront envoyés.\n' +
                                'Etes-vous sûr que vos informations sont correctes ? \n'
                            }
                            onClose={() => setAfficherModaleValidation(false)}
                            onValidate={() =>
                                formulaireService
                                    .envoieDonneesFormulaire(donneesFormulaire)
                                    .then(() => setAfficherEnvoieSucces(true))
                                    .catch(() => setAfficherEnvoieEchec(true))
                            }
                        />
                    )}
                    {afficherEnvoieSucces && (
                        <Alert severity={'success'}>
                            <AlertTitle>Succès</AlertTitle>
                            <AlertDescription>Votre demande a bien été pris en compte</AlertDescription>
                        </Alert>
                    )}
                    {afficherEnvoieEchec && (
                        <Alert severity={'error'}>
                            <AlertTitle>Une erreur est survenue</AlertTitle>
                            <AlertDescription>Votre demande n'a pas été pris en compte</AlertDescription>
                        </Alert>
                    )}
                </form>
            </FormProvider>
        </Container>
    )
}

export default RecherchePersonne
