import React from 'react'
import {
    Button,
    ButtonGroup,
    Icon,
    ModalV2,
    ModalV2Content,
    ModalV2Footer,
    ModalV2Title,
} from '@design-system-etat/dsfr-react'

interface Props {
    content: string
    nomBoutonFermeture?: string
    nomBoutonValidation?: string
    onClose: () => void
    onValidate: () => void
}

const ModaleAvertissement: React.FC<Props> = ({
    content,
    nomBoutonFermeture = 'Annuler',
    nomBoutonValidation = 'Valider',
    onClose,
    onValidate,
}) => {
    return (
        <ModalV2
            id="action-modale-cancel"
            idModalTitle="action-modale-cancel-titre"
            onClose={() => onClose()}
            size="sm"
        >
            <ModalV2Content>
                <ModalV2Title id="action-modale-cancel-titre">
                    <Icon icon="fr-icon-arrow-right-line" /> Êtes-vous sûr ?
                </ModalV2Title>
                {content}
            </ModalV2Content>
            <ModalV2Footer>
                <ButtonGroup align="right" iconPosition="left" inline="lg" reverse>
                    <Button
                        variant="primary"
                        type="button"
                        onClick={() => {
                            onClose()
                            onValidate()
                        }}
                    >
                        {nomBoutonValidation}
                    </Button>
                    <Button variant="secondary" type="button" onClick={() => onClose()}>
                        {nomBoutonFermeture}
                    </Button>
                </ButtonGroup>
            </ModalV2Footer>
        </ModalV2>
    )
}

export default ModaleAvertissement
