import axiosInstance from './AxiosConfiguration'
import type Evenement from '../types/Evenement'

const EvenementService = {
    recupererListeEvenements: async (): Promise<Evenement[]> => {
        const response = await axiosInstance.get('/evenement')
        return response.data
    },
}

export default EvenementService
