import {Button, ButtonGroup, InputControl} from '@design-system-etat/dsfr-react'
import {validateFormField} from '../../constants/ReakHookForm'
import {MESSAGE_ERREUR_CHAMP_INCOMPLET} from '../../constants/MessagesErreurs'
import React, {useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {estAdresseValide} from '../../constants/FonctionCommunes'
import axiosInstance from '../../services/AxiosConfiguration'

interface Props {
    onClose: () => void
    onValidate: (email: string) => void
}

const FormulaireVerificationMail: React.FC<Props> = ({onClose, onValidate}) => {
    const {formState, register, watch, getValues} = useFormContext()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const envoyerCodeAuBackend = async (email: string) => {
        setLoading(true)
        setErrorMessage(null)

        try {
            const response = await axiosInstance.post('/formulaire-web/envoyer-code', {email})

            if (response.status === 200) {
                onValidate(email)
            } else {
                setErrorMessage('Code envoyé, mais la réponse n’est pas valide.')
            }
        } catch {
            setErrorMessage('Une erreur est survenue lors de l’envoi du code')
        } finally {
            setLoading(false)
        }
    }

    const verifierMail = () => {
        const email = watch('email')
        if (estAdresseValide(email)) {
            // Appel au backend
            envoyerCodeAuBackend(getValues('email'))
        } else {
            setErrorMessage("L'adresse e-mail n'est pas valide.")
        }
    }

    return (
        <>
            <InputControl
                label={'Adresse email'}
                id={'email'}
                message={formState.errors.email?.message as string}
                state={validateFormField(formState.errors.email)}
                {...register('email', {
                    required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                })}
            />

            {errorMessage && <div style={{color: 'red', marginBottom: '10px'}}>{errorMessage}</div>}

            <ButtonGroup align="right" iconPosition="left" inline="lg">
                <Button variant="secondary" type="button" onClick={() => onClose()} disabled={loading}>
                    Annuler
                </Button>
                <Button variant="primary" type="button" onClick={verifierMail} disabled={loading}>
                    {loading ? 'Envoi...' : 'Valider'}
                </Button>
            </ButtonGroup>
        </>
    )
}
export default FormulaireVerificationMail
