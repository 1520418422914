import React, {useMemo} from 'react'
import {
    Col,
    FieldsetControl,
    iconRiPhoneFill,
    InputControl,
    Label,
    RadioControl,
    Row,
    SelectControl,
    ToggleV2Control,
} from '@design-system-etat/dsfr-react'
import {listeNationalite} from '../../../constants/ListeNationalite'
import {useFormContext} from 'react-hook-form'
import Adresse from './informationsPersonnelles/Adresse'
import {MESSAGE_ERREUR_CHAMP_INCOMPLET} from '../../../constants/MessagesErreurs'
import {validateFormField} from '../../../constants/ReakHookForm'
import {retourDateDujour} from '../../../constants/FonctionCommunes'

const InformationsPersonnelles: React.FC = () => {
    const {register, watch, formState, getValues} = useFormContext()
    const adresseEtrangere = watch('adresseEtrangere')
    const nationaliteSelectionnee = watch('nationalite')

    const listeNationaliteFormate = useMemo(
        () =>
            listeNationalite.map((nationalite) => ({
                name: nationalite.nationalite,
                value: nationalite.nationalite,
            })),
        [],
    )

    const listeNationaliteSecondaire = useMemo(() => {
        return listeNationaliteFormate.filter((nationalite) => nationalite.value !== nationaliteSelectionnee)
    }, [nationaliteSelectionnee, listeNationaliteFormate])

    return (
        <>
            <FieldsetControl
                id="horizontal-radio-control-civilite"
                inline
                legend="Civilité *"
                messages={[
                    {
                        id: 'id-radio-control-civilite',
                        message: formState.errors.civilite?.message as string,
                    },
                ]}
                state={validateFormField(formState.errors.civilite)}
            >
                <RadioControl
                    id="horizontal-radio-control-1"
                    label="Monsieur"
                    value="Monsieur"
                    {...register('civilite', {
                        required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                    })}
                />
                <RadioControl
                    id="horizontal-radio-control-2"
                    label="Madame"
                    value="Madame"
                    {...register('civilite', {
                        required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                    })}
                />
            </FieldsetControl>

            <Row gutters>
                <Col col={3}>
                    <InputControl
                        id="nom_de_naissance"
                        label="Nom de naissance *"
                        message={formState.errors.nomNaissance?.message as string}
                        state={validateFormField(formState.errors.nomNaissance)}
                        {...register('nomNaissance', {
                            validate: (value) => {
                                const nomUsage = getValues('nomUsage')
                                if (!value && !nomUsage) {
                                    return MESSAGE_ERREUR_CHAMP_INCOMPLET
                                }
                                return true
                            },
                        })}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="nom_usage"
                        label="Nom d'usage **"
                        message={formState.errors.nomUsage?.message as string}
                        state={validateFormField(formState.errors.nomUsage)}
                        {...register('nomUsage', {
                            validate: () => {
                                return true
                            },
                        })}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="prenom"
                        label="Prénom *"
                        message={formState.errors.prenom?.message as string}
                        state={validateFormField(formState.errors.prenom)}
                        {...register('prenom', {
                            required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                        })}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="date_de_naissance"
                        label="Date de naissance"
                        type="date"
                        max={retourDateDujour()}
                        {...register('dateNaissance')}
                    />
                </Col>
            </Row>
            <Row className="fr-mt-2w" gutters>
                <Col col={3}>
                    <SelectControl
                        id="nationalite"
                        options={listeNationaliteFormate}
                        label="Nationalité"
                        placeholder="Sélectionner une nationalité"
                        {...register('nationalite')}
                    />
                </Col>
                <Col col={3}>
                    <SelectControl
                        id="nationalite_secondaire"
                        options={listeNationaliteSecondaire}
                        label="Nationalité secondaire"
                        placeholder="Sélectionner une nationalité"
                        {...register('nationaliteDeux')}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="numero_telephone"
                        label="Téléphone *"
                        icon={iconRiPhoneFill}
                        message={formState.errors.telephone?.message as string}
                        state={validateFormField(formState.errors.telephone)}
                        {...register('telephone', {
                            required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                        })}
                    />
                </Col>
            </Row>

            <Row className="fr-mt-2w fr-mb-2w" gutters>
                <Col col={3}>
                    <InputControl
                        id="email"
                        label="Email *"
                        disabled
                        message={formState.errors.email?.message as string}
                        state={validateFormField(formState.errors.email)}
                        {...register('email', {
                            required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                        })}
                    />
                </Col>
                <Col col={3}>
                    <InputControl
                        id="confirmation_email"
                        label="Confirmation email *"
                        disabled
                        message={formState.errors.email?.message as string}
                        state={validateFormField(formState.errors.email)}
                        {...register('email', {
                            required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                        })}
                    />
                </Col>
            </Row>
            <Label>Adresse étrangère ? *</Label>
            <Col col={1}>
                <ToggleV2Control label={''} id={'adresseEtrangere'} {...register('adresseEtrangere')} />
            </Col>
            <Adresse estFrancais={!adresseEtrangere} />
            <Col>
                <Row className={'legende-formulaire'}>{'* Champ obligatoire'}</Row>
                <Row className={'legende-formulaire'}>{'** Champ obligatoire si nom de naissance manquant'}</Row>
            </Col>
        </>
    )
}

export default InformationsPersonnelles
