import React from 'react'
import {FooterCopyright, FooterLogo, FooterMi, Logo} from '@design-system-etat/dsfr-react'
import {URLPageEnum} from '../../constants/URLPageEnum'
import {NavLink} from 'react-router-dom'

const Footer: React.FC = () => {
    const listeLiensInstitutions = [
        {
            label: 'legifrance.gouv.fr',
            url: 'https://legifrance.gouv.fr',
        },
        {
            label: 'info.gouv.fr',
            url: 'https://info.gouv.fr',
        },
        {
            label: 'service-public.fr',
            url: 'https://service-public.fr',
        },
        {
            label: 'data.gouv.fr',
            url: 'https://www.data.gouv.fr/fr/',
        },
    ]

    return (
        <FooterMi urls={listeLiensInstitutions}>
            <FooterLogo>
                <NavLink to={URLPageEnum.ACCUEIL}>
                    <Logo>
                        <>
                            république
                            <br />
                            française
                        </>
                    </Logo>
                </NavLink>
            </FooterLogo>
            <FooterCopyright>
                <p id="footer-licence">
                    Sauf mention contraire, tous les contenus de ce site sont sous{' '}
                    <a
                        href="https://github.com/etalab/licence-ouverte/blob/master/LO.md"
                        target="_blank"
                        rel="noreferrer"
                    >
                        licence etalab-2.0
                    </a>
                </p>
            </FooterCopyright>
        </FooterMi>
    )
}

export default Footer
