import React, {useState} from 'react'
import {ModalV2, ModalV2Content, ModalV2Title, Row} from '@design-system-etat/dsfr-react'
import FormulaireVerificationMail from './FormulaireVerificationMail'
import FormulaireVerificationCode from './FormulaireVerificationCode'

interface Props {
    onClose: () => void
    onValidate: (email?: string) => void
}

const ModaleMail: React.FC<Props> = ({onClose, onValidate}) => {
    const [email, setEmail] = useState<string>('')

    return (
        <ModalV2
            id="action-modale-cancel"
            idModalTitle="action-modale-cancel-titre"
            onClose={() => onClose()}
            size="md"
        >
            <ModalV2Content>
                <ModalV2Title id="action-modale-cancel-titre">Vérification de l'adresse email</ModalV2Title>
                {email ? (
                    <Row className={'fr-mb-4w'}>
                        Veuillez saisir le code reçu par mail. Le code reçu expire dans 5 minutes
                    </Row>
                ) : (
                    <Row className={'fr-mb-4w'}>
                        Pour accéder au formulaire vous devez saisir un code d’accès. Veuillez renseigner votre adresse
                        email pour recevoir le code
                    </Row>
                )}
                {email ? (
                    <FormulaireVerificationCode onClose={() => onClose()} onValidate={() => onValidate(email)} />
                ) : (
                    <FormulaireVerificationMail onClose={() => onClose()} onValidate={(email) => setEmail(email)} />
                )}
            </ModalV2Content>
        </ModalV2>
    )
}

export default ModaleMail
