import React, {useState} from 'react'
import {
    Button,
    ButtonGroup,
    iconRiAddCircleFill,
    ModalV2,
    ModalV2Content,
    ModalV2Title,
    Row,
    Tile,
    TileBody,
    TileContent,
    TileDetail,
    TileTitle,
} from '@design-system-etat/dsfr-react'
import InformationsPersonneRecherchee from './InformationsPersonneRecherchee'
import './PersonnesRecherchees.css'
import {genererAdresseComplete, genererAdresseEtrangereComplete} from '../../../../constants/FonctionCommunes'
import type DonneesPersonne from '../../../../constants/DonneesPersonne'

interface PersonnesRechercheesProps {
    listePersonnesRecherchees?: DonneesPersonne[]
    setListePersonnesRecherchees: React.Dispatch<React.SetStateAction<DonneesPersonne[]>>
}

const PersonnesRecherchees: React.FC<PersonnesRechercheesProps> = ({
    listePersonnesRecherchees = [],
    setListePersonnesRecherchees,
}: PersonnesRechercheesProps) => {
    const [afficherModale, setAfficherModale] = useState<boolean>(false)
    const [personneAModifier, setPersonneAModifier] = useState<DonneesPersonne | undefined>(undefined)
    const [indexPersonne, setIndexPersonne] = useState<number | undefined>(undefined)

    const modifierListepersonnes = (personne: DonneesPersonne) => {
        if (indexPersonne !== undefined) {
            const listeAModifier = listePersonnesRecherchees
            listeAModifier[indexPersonne] = personne
            setListePersonnesRecherchees([...listeAModifier])
        }
    }

    const fermetureModale = () => {
        setPersonneAModifier(undefined)
        setIndexPersonne(undefined)
        setAfficherModale(false)
    }

    const ouvertureModale = (personneRecherchee: DonneesPersonne | undefined, index: number | undefined) => {
        setPersonneAModifier(personneRecherchee)
        setIndexPersonne(index)
        setAfficherModale(true)
    }

    return (
        <>
            {listePersonnesRecherchees.length > 0 && (
                <Row className={'fr-mb-2w'}>
                    {listePersonnesRecherchees.map((personneRecherchee, index) => (
                        <div className={'tuile-personne-recherchee'} key={index}>
                            <Tile enlargeLink={false} size={'md'}>
                                <TileBody>
                                    <TileContent className={'tuile-contenu'} style={{flexDirection: 'column'}}>
                                        <TileTitle as="h3">Personne recherchée {index + 1}</TileTitle>
                                        <TileDetail className={'tuile-detail-personne-recherchee'}>
                                            <span>
                                                <strong>Civilité:</strong> &nbsp; {personneRecherchee.civilite}
                                            </span>
                                        </TileDetail>
                                        <TileDetail className={'tuile-detail-personne-recherchee'}>
                                            <span>
                                                <strong>Identité:</strong> &nbsp;{' '}
                                                {personneRecherchee.prenom + ' ' + personneRecherchee.nomNaissance}
                                            </span>
                                        </TileDetail>
                                        <TileDetail className={'tuile-detail-personne-recherchee'}>
                                            <span>
                                                <strong>Téléphone:</strong> &nbsp; {personneRecherchee.telephone}
                                            </span>
                                        </TileDetail>
                                        <TileDetail className={'tuile-detail-personne-recherchee'}>
                                            <span>
                                                <strong>Courriel:</strong> &nbsp; {personneRecherchee.email}
                                            </span>
                                        </TileDetail>
                                        <TileDetail className={'tuile-detail-personne-recherchee'}>
                                            <span>
                                                <strong>Nationalité:</strong> &nbsp;{' '}
                                                {personneRecherchee.nationalite +
                                                    (personneRecherchee.nationaliteDeux
                                                        ? '/' + personneRecherchee.nationaliteDeux
                                                        : '')}
                                            </span>
                                        </TileDetail>
                                        <TileDetail className={'tuile-detail-personne-recherchee'}>
                                            <span>
                                                <strong>Adresse:</strong> &nbsp;{' '}
                                                {personneRecherchee.adresseEtrangere
                                                    ? genererAdresseEtrangereComplete(personneRecherchee)
                                                    : genererAdresseComplete(personneRecherchee)}
                                            </span>
                                        </TileDetail>
                                        <TileDetail>
                                            <ButtonGroup inline>
                                                <Button
                                                    size={'sm'}
                                                    variant={'secondary'}
                                                    type={'button'}
                                                    onClick={() =>
                                                        setListePersonnesRecherchees((prevListe) =>
                                                            prevListe.filter((_, i) => i !== index),
                                                        )
                                                    }
                                                >
                                                    Supprimer
                                                </Button>
                                                <Button
                                                    size={'sm'}
                                                    variant={'primary'}
                                                    type={'button'}
                                                    onClick={() => {
                                                        ouvertureModale(personneRecherchee, index)
                                                    }}
                                                >
                                                    Modifier
                                                </Button>
                                            </ButtonGroup>
                                        </TileDetail>
                                    </TileContent>
                                </TileBody>
                            </Tile>
                        </div>
                    ))}
                </Row>
            )}
            <Row>
                <div className={'tuile-personne-recherchee'}>
                    <Tile enlargeLink={false} size={'md'}>
                        <TileBody>
                            <TileContent>
                                <TileDetail>
                                    <ButtonGroup iconPosition={'left'}>
                                        <Button
                                            type={'button'}
                                            onClick={() => {
                                                ouvertureModale(undefined, undefined)
                                            }}
                                            variant={'tertiary-no-outline'}
                                            icon={iconRiAddCircleFill}
                                        >
                                            Ajouter une personne
                                        </Button>
                                    </ButtonGroup>
                                </TileDetail>
                            </TileContent>
                        </TileBody>
                    </Tile>
                </div>
            </Row>
            {afficherModale && (
                <ModalV2 idModalTitle={'id'} onClose={fermetureModale} id={''} size={'lg'}>
                    <ModalV2Content>
                        <ModalV2Title id="simple-modal-title">Personne Recherchée</ModalV2Title>
                        <InformationsPersonneRecherchee
                            personneRecherchee={personneAModifier}
                            onClose={fermetureModale}
                            modificationPersonne={(personne) => modifierListepersonnes(personne)}
                            creationPersonne={(NouvellePersonne) =>
                                setListePersonnesRecherchees((personnePrecedente) => [
                                    ...personnePrecedente,
                                    NouvellePersonne,
                                ])
                            }
                        />
                    </ModalV2Content>
                </ModalV2>
            )}
        </>
    )
}

export default PersonnesRecherchees
