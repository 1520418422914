import React from 'react'
import {Col, Row, SelectControl, SelectGroup} from '@design-system-etat/dsfr-react'
import {useEvenementContext} from '../../../contexts/EvenementContext'
import {useFormContext} from 'react-hook-form'
import {MESSAGE_ERREUR_CHAMP_INCOMPLET} from '../../../constants/MessagesErreurs'
import {validateFormField} from '../../../constants/ReakHookForm'

const SelectionEvenement: React.FC = () => {
    const {evenements} = useEvenementContext()
    const {register, formState} = useFormContext()

    return (
        <Row className="fr-mt-4w">
            <Col col={12}>
                <SelectGroup>
                    <SelectControl
                        id="evenement"
                        label={'Événement *'}
                        options={evenements.map((evenement) => ({
                            name: evenement.intitule,
                            value: evenement.idEvent.toString(),
                        }))}
                        message={formState.errors.evenement?.message as string}
                        state={validateFormField(formState.errors.evenement)}
                        placeholder="Sélectionner un événement"
                        {...register('evenement', {
                            required: MESSAGE_ERREUR_CHAMP_INCOMPLET,
                            valueAsNumber: true,
                        })}
                    />
                </SelectGroup>
            </Col>
        </Row>
    )
}

export default SelectionEvenement
