export const listeNationalite = [
    {nationalite: 'Française'},
    {nationalite: 'Suisse'},
    {nationalite: 'Belge'},
    {nationalite: 'Allemande'},
    {nationalite: 'Italienne'},
    {nationalite: 'Afghane'},
    {nationalite: 'Albanaise'},
    {nationalite: 'Algerienne'},
    {nationalite: 'Americaine'},
    {nationalite: 'Andorrane'},
    {nationalite: 'Angolaise'},
    {nationalite: 'Antiguaise et barbudienne'},
    {nationalite: 'Argentine'},
    {nationalite: 'Armenienne'},
    {nationalite: 'Australienne'},
    {nationalite: 'Autrichienne'},
    {nationalite: 'Azerbaïdjanaise'},
    {nationalite: 'Bahamienne'},
    {nationalite: 'Bahreinienne'},
    {nationalite: 'Bangladaise'},
    {nationalite: 'Barbadienne'},
    {nationalite: 'Belizienne'},
    {nationalite: 'Beninoise'},
    {nationalite: 'Bhoutanaise'},
    {nationalite: 'Bielorusse'},
    {nationalite: 'Birmane'},
    {nationalite: 'Bissau-Guinéenne'},
    {nationalite: 'Bolivienne'},
    {nationalite: 'Bosnienne'},
    {nationalite: 'Botswanaise'},
    {nationalite: 'Bresilienne'},
    {nationalite: 'Britannique'},
    {nationalite: 'Bruneienne'},
    {nationalite: 'Bulgare'},
    {nationalite: 'Burkinabe'},
    {nationalite: 'Burundaise'},
    {nationalite: 'Cambodgienne'},
    {nationalite: 'Camerounaise'},
    {nationalite: 'Canadienne'},
    {nationalite: 'Cap-verdienne'},
    {nationalite: 'Centrafricaine'},
    {nationalite: 'Chilienne'},
    {nationalite: 'Chinoise'},
    {nationalite: 'Chypriote'},
    {nationalite: 'Colombienne'},
    {nationalite: 'Comorienne'},
    {nationalite: 'Congolaise'},
    {nationalite: 'Costaricaine'},
    {nationalite: 'Croate'},
    {nationalite: 'Cubaine'},
    {nationalite: 'Curaçaoane'},
    {nationalite: 'Danoise'},
    {nationalite: 'Djiboutienne'},
    {nationalite: 'Dominicaine'},
    {nationalite: 'Dominiquaise'},
    {nationalite: 'Egyptienne'},
    {nationalite: 'Emirienne'},
    {nationalite: 'Equato-guineenne'},
    {nationalite: 'Equatorienne'},
    {nationalite: 'Erythreenne'},
    {nationalite: 'Espagnole'},
    {nationalite: 'Est-timoraise'},
    {nationalite: 'Estonienne'},
    {nationalite: 'Ethiopienne'},
    {nationalite: 'Fidjienne'},
    {nationalite: 'Finlandaise'},
    {nationalite: 'Gabonaise'},
    {nationalite: 'Gambienne'},
    {nationalite: 'Georgienne'},
    {nationalite: 'Ghaneenne'},
    {nationalite: 'Grenadienne'},
    {nationalite: 'Guatemalteque'},
    {nationalite: 'Guineenne'},
    {nationalite: 'Guyanienne'},
    {nationalite: 'Haïtienne'},
    {nationalite: 'Hellenique'},
    {nationalite: 'Hondurienne'},
    {nationalite: 'Hongroise'},
    {nationalite: 'Hongkongaise'},
    {nationalite: 'Indienne'},
    {nationalite: 'Indonesienne'},
    {nationalite: 'Irakienne'},
    {nationalite: 'Iranienne'},
    {nationalite: 'Irlandaise'},
    {nationalite: 'Islandaise'},
    {nationalite: 'Israélienne'},
    {nationalite: 'Ivoirienne'},
    {nationalite: 'Jamaïcaine'},
    {nationalite: 'Japonaise'},
    {nationalite: 'Jordanienne'},
    {nationalite: 'Kazakhstanaise'},
    {nationalite: 'Kenyane'},
    {nationalite: 'Kirghize'},
    {nationalite: 'Kiribatienne'},
    {nationalite: 'Kittitienne-et-nevicienne'},
    {nationalite: 'Kossovienne'},
    {nationalite: 'Koweitienne'},
    {nationalite: 'Laotienne'},
    {nationalite: 'Lesothane'},
    {nationalite: 'Lettone'},
    {nationalite: 'Libanaise'},
    {nationalite: 'Liberienne'},
    {nationalite: 'Libyenne'},
    {nationalite: 'Liechtensteinoise'},
    {nationalite: 'Lituanienne'},
    {nationalite: 'Luxembourgeoise'},
    {nationalite: 'Macedonienne'},
    {nationalite: 'Malaisienne'},
    {nationalite: 'Malawienne'},
    {nationalite: 'Maldivienne'},
    {nationalite: 'Malgache'},
    {nationalite: 'Malienne'},
    {nationalite: 'Maltaise'},
    {nationalite: 'Marocaine'},
    {nationalite: 'Marshallaise'},
    {nationalite: 'Mauricienne'},
    {nationalite: 'Mauritanienne'},
    {nationalite: 'Mexicaine'},
    {nationalite: 'Micronesienne'},
    {nationalite: 'Moldave'},
    {nationalite: 'Monegasque'},
    {nationalite: 'Mongole'},
    {nationalite: 'Montenegrine'},
    {nationalite: 'Mozambicaine'},
    {nationalite: 'Namibienne'},
    {nationalite: 'Nauruane'},
    {nationalite: 'Neerlandaise'},
    {nationalite: 'Neo-zelandaise'},
    {nationalite: 'Nepalaise'},
    {nationalite: 'Nicaraguayenne'},
    {nationalite: 'Nigeriane'},
    {nationalite: 'Nigerienne'},
    {nationalite: 'Nord-coréenne'},
    {nationalite: 'Norvegienne'},
    {nationalite: 'Omanaise'},
    {nationalite: 'Ougandaise'},
    {nationalite: 'Ouzbeke'},
    {nationalite: 'Pakistanaise'},
    {nationalite: 'Palestinienne'},
    {nationalite: 'Panameenne'},
    {nationalite: 'Papouane-neoguineenne'},
    {nationalite: 'Paraguayenne'},
    {nationalite: 'Peruvienne'},
    {nationalite: 'Philippine'},
    {nationalite: 'Polonaise'},
    {nationalite: 'Portoricaine'},
    {nationalite: 'Portugaise'},
    {nationalite: 'Qatarienne'},
    {nationalite: 'Roumaine'},
    {nationalite: 'Russe'},
    {nationalite: 'Rwandaise'},
    {nationalite: 'Saint-Lucienne'},
    {nationalite: 'Saint-Marinaise'},
    {nationalite: 'Saint-Martinoise'},
    {nationalite: 'Saint-Vincentaise-et-Grenadine'},
    {nationalite: 'Salomonaise'},
    {nationalite: 'Salvadorienne'},
    {nationalite: 'Samoane'},
    {nationalite: 'Santomeenne'},
    {nationalite: 'Saoudienne'},
    {nationalite: 'Senegalaise'},
    {nationalite: 'Serbe'},
    {nationalite: 'Seychelloise'},
    {nationalite: 'Sierra-leonaise'},
    {nationalite: 'Singapourienne'},
    {nationalite: 'Slovaque'},
    {nationalite: 'Slovene'},
    {nationalite: 'Somalienne'},
    {nationalite: 'Soudanaise'},
    {nationalite: 'Sri-lankaise'},
    {nationalite: 'Sud-africaine'},
    {nationalite: 'Sud-coréenne'},
    {nationalite: 'Sud-soudanaise'},
    {nationalite: 'Suedoise'},
    {nationalite: 'Surinamaise'},
    {nationalite: 'Syrienne'},
    {nationalite: 'Tadjike'},
    {nationalite: 'Tanzanienne'},
    {nationalite: 'Tchadienne'},
    {nationalite: 'Tcheque'},
    {nationalite: 'Thailandaise'},
    {nationalite: 'Togolaise'},
    {nationalite: 'Tongienne'},
    {nationalite: 'Trinidadienne'},
    {nationalite: 'Tunisienne'},
    {nationalite: 'Turkmene'},
    {nationalite: 'Turque'},
    {nationalite: 'Tuvaluane'},
    {nationalite: 'Ukrainienne'},
    {nationalite: 'Uruguayenne'},
    {nationalite: 'Vanuatuane'},
    {nationalite: 'Vaticane'},
    {nationalite: 'Venezuelienne'},
    {nationalite: 'Vietnamienne'},
    {nationalite: 'Yemenite'},
    {nationalite: 'Zambienne'},
    {nationalite: 'Zimbabweenne'},
]
